import React,{ useState, useEffect,useRef} from 'react';
// import { Link } from 'react-router-dom';
// import Popup from '../popup_component/Popup';
import agentbg from '../../images/agent_bg.jpg';
import agentuser from '../../images/default_user.jpg';
import './Premium_partner.css';

function Premiumpartner() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [premium, setPremium] = useState("");
  const [timeLogin,setTimeLogin] = useState(false);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const api_url = 'https://www.travsie.com/admin/';
  useEffect(()=>{
    getPagetitle();
    getagentData();
  },[])

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);    
      setCountDown(0);   

    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const getagentData=()=>{
   // alert("resD");
    let formData = new FormData(); 
    formData.append('action',"agentlist");

    fetch(api_url+"premium_partner.php",{
      method:'POST',
      headers: {
        'Accept':'application/json',
      },
      body:formData,
      }).then((response)=> response.text()).then((resData)=> {
        var JsonData=JSON.parse(resData);
        setPremium(JsonData);      
         for(var v=0; v<Object.keys(JsonData).length;v++){              
              document.getElementById("star_"+JsonData[v]['agent_id']).innerHTML=JsonData[v]['star_rating'];
         } 
      })
  
  }

  const getPagetitle = ()=>{
    document.title = 'Premium Parnter';
  }

  const Connectbtn=(i)=>{
    setRunTimer(false);
    setCountDown(0);   
    document.getElementsByClassName("errorprint_"+i)[0].innerHTML="";
    document.getElementsByClassName("timesend").innerHTML="00:00";
    setOtp("");
    document.getElementById("otpbtn"+i).style.display="block";
    document.getElementById("otpvalue"+i).style.display="none";   
    document.getElementById("showbtn"+i).style.display="none";   
   

    const nodeList = document.querySelectorAll(".timesend");
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].style.display="none";
    }
     var getclass=document.querySelectorAll(".Agentform");
     for(let i=0;i<getclass.length;i++){
        getclass[i].classList.remove("active");
     }


     document.getElementById("Agentformdet_"+i).classList.add("active");
  }
  const clsconnectbtn=(i)=>{
     setRunTimer(false);
     setCountDown(0);
     
    document.getElementsByClassName("errorprint_"+i)[0].innerHTML="";
    document.getElementsByClassName("timesend").innerHTML="00:00";
    const nodeList = document.querySelectorAll(".timesend");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.display="none";
      }
    document.getElementById("Agentformdet_"+i).classList.remove("active");
    document.getElementById("otpvalue"+i).style.display="none";
    document.getElementById("showbtn"+i).style.display="none";
    document.getElementById("otpbtn"+i).style.display="block";
  }
 
const OtpDetails=(i)=>{
  let formData = new FormData(); 
  document.getElementById("otpvalue"+i).display="block";
  formData.append('action',"otpdetail");
  formData.append('mobile',mobile); 
  formData.append('agent_id',i); 
 
  document.getElementsByClassName("errorprint_"+i)[0].innerHTML="";
  document.getElementsByClassName("errorprint_"+i)[0].style.color="red";
  if(mobile===''){
    document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter Mobile Number."
    return false;
  }     
  if(mobile!==''){
    let mobCheck = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);    
    if(!mobCheck.test(mobile)) {
        document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter correct Mobile.";
        return false;
    }      
    if(mobile.length < 10 ){      
        document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter Valid Mobile Number.";
        return false;
    }  
  }  

  
    fetch(api_url+"premium_partner.php",{
      method:'POST',
      headers: {
        'Accept':'application/json',
      },
      body:formData,
      }).then((response)=> response.text()).then((resData)=> {
          // resData="ok";
        if(resData==="ok"){  
          setTimeLogin(true);
          setRunTimer(true);    
          document.getElementsByClassName("errorprint_"+i)[0].innerHTML="OTP is send to your mobile number";
          document.getElementsByClassName("errorprint_"+i)[0].style.color="green";
          document.getElementById('timesend'+i).style.display='block';
          document.getElementById("otpvalue"+i).style.display="block";
          document.getElementById("showbtn"+i).style.display="block";
          document.getElementById("otpbtn"+i).style.display="none";
        }
      })
  
} 
const ShowDetails=(i)=>{  
   
    let formData = new FormData(); 
    formData.append('action',"agentdetail");
    formData.append('agent_id',i);
    formData.append('name',name);
    formData.append('email',email);
    formData.append('mobile',mobile); 
    formData.append('otp',otp); 
    document.getElementsByClassName("errorprint_"+i)[0].style.color="red";
    if(name==='' || email==='' || mobile===''){
        document.getElementsByClassName("errorprint_"+i)[0].innerHTML="You must fill in all of the fields!"
        return false;
    }     
   
    if(mobile!==''){
      let mobCheck = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);  
      if(!mobCheck.test(mobile)){
          document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter correct Mobile Number.";
          return false;
      }      
      if(mobile.length < 10 ){        
          document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter Valid Mobile Number.";
          return false;
      }    
    } 
    if(otp==='' && otp.length<'6'){    
      document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter OTP.";
      return false;
    }
    
    if(name!=='' && email!=='' &&  mobile!==''){   
        fetch(api_url+"premium_partner.php",{
          method:'POST',
          headers: {
            'Accept':'application/json',
          },
          body:formData,
          }).then((response)=> response.text()).then((resData)=> {
              var JsonData=JSON.parse(resData);         
              if(JsonData['otp']===otp){           
                document.getElementsByClassName("errorprint_"+i)[0].innerHTML="";
                document.getElementById("contact_det_"+i).classList.add("active");
                document.getElementsByClassName("errorprint_"+i)[0].innerHTML=""
                document.getElementById("Agentformdet_"+i).classList.remove("active");
                document.getElementById("web_"+i).innerText=JsonData['website_name'];
                document.getElementById("web_"+i).href =JsonData['website_name'];
                document.getElementById("email_"+i).innerText=JsonData['email'];
                document.getElementById("mobile_"+i).innerText=JsonData['mobile'];   
               
              }
              else{
                document.getElementsByClassName("errorprint_"+i)[0].innerHTML="Please Enter Correct otp.";
              }       
              
          })
      }
  
}

  return (
    <section className="row_hb">      
      <div className='partnerbg pos_relative' style={{background:`url(${agentbg})`}}>
          <h2 className="row_hb fnt_weight_800 mar_a_0 pad_a_0 mob_text_align_c fnt_size_50 fnt_size_xs_30" style={{color:"#fff"}}>Premium <span> Patner</span></h2>
          <p className="row_hb fnt_size_20 fnt_size_xs_15 mar_t_5 mob_m_t_0_ font_fam_halvetica" style={{color:"#fff"}}>TRAVEL AGENTS IN INDIA</p>
          <div className='row_hb display_none' >
            <div className='container'> 
              <div className='row_hb fillterbox'>
                <span className='row_hb b mar_b_20' style={{fontSize:"16px",fontWeight:"500"}}>Search for an expert travel agent in one or more of the following ways.</span>
                <div className='row_hb fillterinpbx'>
                  <span class="grid_30 grid_xs_100 mar_r_10 pad_r_xs_0 mar_b_xs_10 pos_relative serbox">
                    <input type="text" className='form-control' placeholder='Search by Location'  />
                    <i className='fa fa-search'></i>
                  </span>
                  <span class="grid_30 grid_xs_100 mar_r_10 pad_r_xs_0 mar_b_xs_10 pos_relative serbox">
                    <input type="text"  className='form-control' placeholder='Search by Destination'  />
                    <i className='fa fa-search'></i>
                  </span>               
                  <input type="button" onClick={getagentData} className='btn btn-primary float_l float_xs_r grid_20 grid_xs_100' value="Find Agent" />              
                </div>
              </div>
            </div>
          </div>
      </div>
        <div className="row_hb signUpCntr blogs mob_p_t_25 pad_t_50 pad_b_10">
          <div className="container">            
            {Object.keys(premium).length>0?
            <div className='row_hb findlistbox'>   
                {premium.map((item,indx)=>            
                  <div className='premiumlistitem'  key={`agent_${indx}`}>
                      <div className='grid_15 grid_xs_100'>
                        {/* <img src={agentlogo} alt="agent name" className='agent_img' /> */}
                        {item.img_name===""?
                           <img src={agentuser} alt="agent name" className='agent_img' />
                          
                          :
                          // <img src={item.img_name} alt="agent name" className='agent_img' />   
                          <img src={agentuser} alt="agent name" className='agent_img' />                       
                        }
                        
                        
                      </div>
                      <div className='grid_60 mar_r_10 mar_l_10 grid_xs_100 mar_t_xs_10 pad_lr_xs_0'>
                          <span className='row_hb fnt_size_18 mar_b_5 b' style={{color:"#337ab7"}}>Premium Partner</span>
                          <span className='row_hb fnt_size_20 mar_b_5 b'  style={{textTransform:"uppercase"}}>{item.full_name}</span>
                          <span className='row_hb  fnt_size_13' style={{color:"#777"}}>{item.country}</span>
                          <span className='row_hb  mar_b_10 fnt_size_14' >{item.description}</span>
                          <span className='row_hb  mar_b_5 b' style={{color:"#0c87a3"}}>{item.company}</span>
                          <span className='row_hb  mar_b_5'>
                            <span className='b mar_r_5'>Destination Specialties:</span>
                            <span>{item.destination}</span>
                          </span>
                          <span className="contact_details fnt_size_15 display_none" id={`contact_det_${item.agent_id}`}>
                            <span className='row_hb mar_b_5 b' style={{color:"#0c87a3"}}>Connect to Agent

                            <span className='mobileagent float_r fnt_size_13'>
                              {/* <Link to={`${item.website_name}`}>{item.website_name}</Link> */}
                              <a href="#" id={`web_${item.agent_id}`}></a>
                            </span>
                            </span>
                              <span className='grid_40 mar_r_5 mar_r_xs_0 grid_xs_100'>
                                <span className='b mar_r_10'><i className='fa fa-envelope mar_r_5'></i>Email Id:</span>
                                <span id={`email_${item.agent_id}`} className='emailagent'></span>
                              </span>
                              <span className='grid_50  grid_xs_100'>
                                <span className='b mar_r_10'><i className='fa fa-phone mar_r_5'></i> Mobile No:</span>
                                <span id={`mobile_${item.agent_id}`} className='mobileagent'></span>
                              </span>
                            
                              
                          </span>
                          
                      </div>
                      <div className='grid_20 float_r text_align_c grid_xs_100'>
                          {/* <span className='row_hb mar_b_10'>
                              <img src={trvlogo} alt="travsie" style={{float:"right",width:"150px;"}} />
                          </span> */}
                          <span className='row_hb fastar mar_b_5 text_align_c' id={`star_${item.agent_id}`}>                          
                           
                            {/* <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i> */}
                            
                            
                          </span>
                          <span className='row_hb fnt_size_13 revbox'>
                            <span className='row_hb'>
                              <span>{item.review}</span> reviews
                            </span>
                            <span className='row_hb'>
                              100% Recommended
                            </span>
                            <span className='row_hb'>
                            {item.year_exp}+ Years of Experience
                            </span>
                          </span>
                        
                          <span className='row_hb pos_relative'>
                            <input type="button" className='btn btn-primary' onClick={()=>Connectbtn(`${item.agent_id}`)} style={{ width:"140px",borderRadius:"50px"}} value="Connect" />
                            <span className='Agentform' id={`Agentformdet_${item.agent_id}`}>
                                <span className='row_hb text_align_l mar_b_5'>
                                  <span className='b mar_b_5' style={{color:"#0c87a3"}}>Enter Your Details</span>
                                  <i className='fa fa-times float_r pointer' onClick={()=>clsconnectbtn(`${item.agent_id}`)}></i>
                                </span>
                                <form action="" className='row_hb'>
                                  <input type="text" className='form-control mar_b_5' value={name} onChange={(e)=>setName(e.target.value)} name="Name" placeholder='Enter your Name' />
                                  <input type="text" className='form-control mar_b_5' value={email} onChange={(e)=>setEmail(e.target.value)} name="emailid"  placeholder='Enter Email Id'  />
                                  <input type="text" className='form-control mar_b_10' value={mobile} onChange={(e)=>setMobile(e.target.value)}  name="mobileno"  placeholder='Enter Mobile Number'  />
                                  <div className='row_hb' style={{position:"relative"}}>
                                    <input type="text" className='form-control mar_b_10 display_none' value={otp} onChange={(e)=>setOtp(e.target.value)} id={`otpvalue${item.agent_id}`}   name="otp"  placeholder='Enter otp'  />
                                  <div className={`${timeLogin ? "timesend" : "display_none"}`} id={`timesend${item.agent_id}`} style={{'position':'absolute','top':'6px','right':'10px','color':'green'}}>
                                    {minutes}:{seconds}
                                    </div>
                                  </div>
                                    <input type="button" className='btn btn-info btn-sm mar_b_5' id={`otpbtn${item.agent_id}`}  onClick={()=>OtpDetails(`${item.agent_id}`)} value="Get OTP"  />
                                  <input type="button" className='btn btn-primary btn-sm mar_b_5 display_none' style={{margin:"0 auto"}} id={`showbtn${item.agent_id}`}  onClick={()=>ShowDetails(`${item.agent_id}`)} value="Submit"  />
                                  <span className={`row_hb errorprint_${item.agent_id}`} style={{color:"red",fontSize:"12px"}}></span>
                                </form>
                            </span>
                          </span>

                      </div>
                      

                  </div>   
                )}            
            </div>
             :null}
          </div>
        </div>
    </section>
  );
}

export default Premiumpartner;