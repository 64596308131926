import React,{ useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import * as animationjs from '../../animation';
import supplierTrav from '../../images/supplierTrav.png';
import supplier_img from '../../images/supplier_img.gif';
import caretIcon from '../../images/caretIcon.png';
import mobile_scrn from '../../images/mobile_scrn.png';
import supplierBottom from '../../images/supplierBottom.gif';
import './Supplier.css';

function Supplier() {

  useEffect(()=>{
    getPagetitle();
  },[]);

  const teamData = [
    {image:'agent_icon_1.png', data:'Base shift to online presence'},
    {image:'supplier_icon_2.png', data:'Real-time access to agents market – No old-school emails'},
    {image:'icon_7.png', data:'Sell, promote and service, all on a single platform'},
    {image:'agent_icon_8.png', data:'Predictive analytics to forcast & grow your business '},

    {image:'supplier_icon_8.png', data:'Enter any target market without having a physical presence'},
    {image:'agent_icon_5.png', data:'Wider choice of products to sell in agent’s market'},
    {image:'agent_icon_7.png', data:'Digitizing your business leads to efficient processes'},
    {image:'icon_6.png', data:'Business opportunities through Travsie travel agents’ network'}
  ];

  const getPagetitle = ()=>{
    document.title = 'Supplier';
  }

  const addAniClass = (cls,n)=>{
    if(window.innerWidth>767){
      animationjs.addAniClass(cls,n);
    }
  }

  const removeAniClass = (cls)=>{
    if(window.innerWidth>767){
      animationjs.removeAniClass(cls);
    }
  }

  const moveImg = (imgId)=>{
    if (document.getElementById(imgId) != null) {
      document.getElementById(imgId).src = supplierBottom;
    }
  }

  return (
    <section className="row_hb holidaySCntr pad_t_50">
      <Waypoint onEnter={()=>addAniClass('flipCntr','1')} onLeave={()=>removeAniClass('flipCntr')}>
        <div className="row_hb flipCntr">
          <div className="container">
            <div className="row_hb text_align_c pos_relative index_2 mob_no_z_indx">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0">
                  <div className="row_hb pad_t_25 teamMobile">
                    <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">Travsie for  <span className="txt_color_3 mob_d_block">Holiday Suppliers</span></h2>
                    <p className="fnt_size_22 mob_p_t_15 mar_a_0 pad_t_25 font_fam_halvetica mob_d_none_1024 pad_l_r_120">We are getting rid of the challenges travel suppliers face<br/> no more “Dealing for one with many”. </p>
                    <p className="fnt_size_22 mob_p_t_15 mar_a_0 pad_t_25 font_fam_halvetica mob_d_block_1024 display_none pad_l_r_120">We are getting rid of the challenges travel suppliers face – no more “Dealing for one with many”. </p>
                  </div>
              </div>
            </div>

            <div className="row_hb pad_b_50 mar_t_30 pos_relative ">
              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 pad_l_0 flipElm animated mob_p_r_0">
                <div className="row_hb pad_t_25 mob_p_t_0">
                  <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 pad_l_0 mob_p_r_0">
                    <div className="row_hb">
                      <div className="row_hb mar_t_30 pos_relative pad_a_25 bg_what_how innerElm mob_min_ht border_rad_8">
                        <h2 className="txt_color_5 bg_agent pad_a_15 mob_font_size_15 mob_p_a mar_a_0 box_sha_agent border_rad_8 enhanceElm left pos_absolute fnt_weight_800 fnt_size_18">Enhancing your  <span className="txt_color_3 pos_relative">Strengths<span className="agentLIcon mob_d_none pos_absolute index_1"></span></span></h2>

                        <ul className="row_hb mar_a_0 doULElm innerElm mob_p_l_0">
                          <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">Product-market fit</span>
                          </li>
                          <li className="row_hb pad_b_20 mob_p_b_15">
                            <img src={caretIcon} alt=""/>
                              <span class="font_fam_halvetica fnt_size_15">Perfectly managed tour management systems</span>
                          </li>
                          <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">Personal attention to every customer</span>
                          </li>
                          <li className="row_hb">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">Training on new products</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img className="midImgElm mob_pos_unset mob_row_hb supp pos_absolute" src={supplier_img} alt=""/>
              <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 pull-right pad_r_0 flipElmX animated mob_p_l_0">
                <div className="row_hb pad_t_25">
                  <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 pull-right pad_r_0 mob_p_l_0">
                    <div className="row_hb">
                      <div className="row_hb mar_t_30 mar_t_0_768 pad_a_25 pos_relative bg_what_how innerElm mob_min_ht border_rad_8">
                        <h2 className="txt_color_5 bg_agent pad_a_15 mar_a_0 mob_p_a mob_font_size_15 box_sha_agent border_rad_8 enhanceElm right pos_absolute fnt_weight_800 fnt_size_18">Removing <span className="txt_color_3">Challenges <span className="agentLIcon agentRicon mob_d_none pos_absolute index_3"></span></span></h2>

                        <ul className="row_hb mar_a_0 doULElm innerElm mob_p_l_0">
                          <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">No more dependency on middle-man</span>
                          </li>
                          <li className="row_hb pad_b_20 mob_p_b_15">
                            <img src={caretIcon} alt=""/>
                              <span class="font_fam_halvetica fnt_size_15">No more limited access to agents market </span>
                          </li>
                          <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">Go beyond emails - real time access to agents market </span>
                          </li>
                          <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">No more manual processes or delayed responses </span>
                          </li>
                          <li className="row_hb">
                              <img src={caretIcon} alt=""/>
                              <span className="font_fam_halvetica fnt_size_15">Didn’t have market trends? You do now!  </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </Waypoint>

      <div className="row_hb pad_t_50 pad_b_30 bg_col">                  
        <div className="container">
          <div className="row_hb text_align_c">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0">
                <div className="row_hb teamMobile">
                  <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50"><span className="txt_color_3">Solutions </span> by Travsie   </h2>
                  <p className="fnt_size_22 mar_a_0 pad_t_25 mob_p_t_15 font_fam_halvetica mob_d_none pad_l_r_120">Travsie automates and gives suppliers the speed, agility and innovation needed to <br/>take over new markets without a physical presence </p>
                  <p className="fnt_size_22 mar_a_0 pad_t_25 mob_p_t_15 font_fam_halvetica mob_d_block display_none pad_l_r_120">Travsie automates and gives suppliers the speed, agility and innovation needed to take over new markets without a physical presence </p>
                </div>
            </div>
          </div>

          <div className="row_hb pad_t_50 mob_p_t_0 mob_p_t_50_1024">
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 pad_r_0 pad_l_0 pos_relative">
                <div className={`aboutAnimate moveimgBgSmBnft supplierEl index_1 pos_absolute`}></div>
                <div className="row_hb pos_relative index_2 mob_p_b_35">
                    <img className="mobAgent supplier" src={mobile_scrn} alt=""/>
                </div>
            </div>

            <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9 pull-right pad_l_0 pad_r_0">
                <div className="row_hb text_align_c">
                  {teamData.length>0?
                    <div className="row_hb">
                        {teamData.map((item,indx)=>
                        <div key={`team_${indx}`} className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                          <div className="row_hb agentBox innerBx mob_min_ht border_rad_8 text_align_c pad_a_20 pos_relative">
                              <img className="mar_t_10" src={`${window.location.origin}/images/${item.image}`} alt=""/>
                              <p className="font_fam_halvetica row_hb fnt_size_14 line_height_22"> {item.data}</p>
                          </div>
                        </div>
                        )}
                    </div>
                  :null}
                </div>
            </div>
          </div>

        </div>
      </div>

      <div className="row_hb pad_t_50 pad_b_50">
        <div className="container">
          <div className="row_hb text_align_c">

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_r_0 pad_r_0 mob_p_l_0 pos_relative">
              <div className={`aboutAnimate moveimgBgSm  agentDx index_1 pos_absolute`}></div>
              <div className="row_hb text_align_c disp_flex pos_relative index_2">
                  <img className="agentTrav" src={supplierTrav} alt=""/>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0 pos_relative index_2">
                <div className="row_hb teamMobile">
                  <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">Be a part of the Next-Gen <br/> Holiday business. <span className="txt_color_3">Register now! </span></h2>
                  <p className="fnt_size_20 mar_a_0 mob_p_t_15 pad_t_25 txt_color_3 font_fam_halvetica mob_d_none pad_l_r_120">Sign up now for free on Travsie and get access to travel agents <br/>market in 100+ cities in India </p>
                  <p className="fnt_size_20 mar_a_0 mob_p_t_15 pad_t_25 txt_color_3 font_fam_halvetica mob_d_block display_none pad_l_r_120">Sign up now for free on Travsie and get access to travel agents market in 100+ cities in India  </p>
                </div>

                <div className="row_hb pad_t_30">
                  <Link to="/signup/supp" className="btn btn-sm btnAgent mob_m_r_0 mar_r_20 bg_light_blue txt_color_4 text_align_c fnt_weight_500 border_rad_8 fnt_size_15 min_width_140">Get started	<i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                </div>
            </div>
          </div>
        </div>
      </div>
      
      <Waypoint onEnter={()=>moveImg('supplierMoveImg')}>
        <div className="row_hb bg_col">
          <img id="supplierMoveImg" className="row_hb" src="" alt=""/>
        </div>
      </Waypoint>

    </section>
  );
}

export default Supplier;