import { Link } from 'react-router-dom';
import './Footer.css';
import adtiologo from '../../images/adtoi-logo.jpg';


function Footer() {

  const gonextHide = (cls,idd)=>{
    if (document.querySelector('.'+cls+'.active') != null) {
      document.querySelector('.'+cls+'.active').classList.remove('active');
    }
    document.querySelector('#'+idd).classList.add('active');
  }

  return (
    <footer className="row_hb pad_t_40 bg_footer">
      <div className="container">
        <div className="row_hb">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pad_l_0 pad_r_50 mob_p_r_0 m_dx_50">
              <div className="row_hb bor_r_1 nomobBor">
                <h3 className="row_hb txt_color_3 mob_font_size_18 fnt_size_22 fnt_weight_900 mar_t_0">Travshop Private Limited </h3>
                <p className="font_fam_roboto txt_color_1 mob_font_s_14 fnt_size_15 line_height_24">315/274, 2nd Floor Westend Marg<br/> Said-ul-Ajaib, New Delhi - 110030<br/> +91 - 9818278899</p>
                <img src={adtiologo} alt="adtiologo" style={{width:'150px'}} />
              </div>
          </div>

          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mob_p_r_0 mob_p_l_0 mob_p_t_25">
              <h3 className="row_hb txt_color_3 fnt_size_22 mob_font_size_18 fnt_weight_900 mar_t_0">Company </h3>
              <ul className="row_hb font_fam_roboto fnt_size_15 mob_font_s_14 line_height_24">
                  <li><Link to="/about" id="aboutLink" onClick={()=>gonextHide('mLink','aboutLink')} className="mLink txt_color_1">About Us</Link></li>
                  <li><Link to="/agent" id="agentsLink" onClick={()=>gonextHide('mLink','agentsLink')} className="mLink txt_color_1">Travsie for Agents</Link></li>
                  <li><Link to="/supplier" id="suppliersLink" onClick={()=>gonextHide('mLink','suppliersLink')}  className="mLink txt_color_1">Travsie for Suppliers</Link></li>
                  <li><Link to="/benefit" id="benefitsLink" onClick={()=>gonextHide('mLink','benefitsLink')} className="mLink txt_color_1">Benefits </Link></li>
                  <li><Link to="/blogs" id="blogsLink" onClick={()=>gonextHide('mLink','blogsLink')} className="mLink txt_color_1">Blogs</Link></li>
              </ul>
          </div>
          
          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mob_p_r_0 mob_p_l_0 mob_p_t_25">
              <h3 className="row_hb txt_color_3 fnt_size_22 mob_font_size_18 fnt_weight_900 mar_t_0">Others </h3>
              <ul className="row_hb font_fam_roboto txt_color_1 mob_font_s_14 fnt_size_15 line_height_24">
                  <li><Link to="/terms" id="tacLink" onClick={()=>gonextHide('mLink','tacLink')} className="mLink txt_color_1">Terms and Conditions </Link></li>
                  <li><Link to="/unsubscribe" id="unsubscribe" onClick={()=>gonextHide('mLink','unsubscribe')} className="mLink txt_color_1">Unsubscribe</Link></li>
                  <li><Link to="/privacy" id="privacyLink" onClick={()=>gonextHide('mLink','privacyLink')} className="mLink txt_color_1">Privacy Policy</Link></li>
                  <li><Link to="/infringement" id="infringementLink" onClick={()=>gonextHide('mLink','infringementLink')} className="mLink txt_color_1">Infringement Policy</Link></li>
              </ul>
          </div>

          <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 pad_r_0 mob_p_r_0 mob_p_l_0 mob_p_t_25">
              <h3 className="row_hb txt_color_3 fnt_size_22 mob_font_size_18 fnt_weight_900 mar_t_0">Contact </h3>
              <ul className="row_hb font_fam_roboto txt_color_1 mob_font_s_14 fnt_size_15 line_height_24">
                  <li><a href="mailto:discover@travsie.com" className="txt_color_1">discover@travsie.com</a></li>
              </ul>
          </div>

          <div className="row_hb text_align_c" style={{padding:'15px 0',fontSize:'11px',color:'#666'}}>
            <p style={{margin:'0px'}}>CopyRight © 2021 Travshop Pvt. Ltd. All Rights Reserved</p>
          </div>
          
        </div>
      </div>
    </footer>
  );
}

export default Footer;