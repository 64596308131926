import React,{ useState, useEffect,useRef} from 'react';
import { useParams, Link,Routes, Route,useHistory} from 'react-router-dom';
import Popup from '../popup_component/Popup';
import * as animationjs from '../../animation';

import './Signup.css';

function Signup() {
  const { type } = useParams();
  const [ip, setIP] = useState('');

  const [typeCheck,setTypeCheck] = useState('');
  const [typeName,setTypeName] = useState('');

  const [onSuccess,setOnSuccess] = useState({flag:false,message:'',action:''});
  const [loginData,setLoginData] = useState({name:'',agency:'',pincode:'',city:'',mobile:'',email:'',otp:'',password:'',re_password:'',login_type:type,action:'registration',captcha:'',state:'',country:'',website:''});

  const [otpSendMsg,setOtpSendMsg] = useState({flag:'',data:'',type:''});
  const [fnameMsgAlert,setFnameMsgAlert] = useState(false); 
  const [agencyMsgAlert,setAgencyMsgAlert] = useState(false); 
  const [countryMsgAlert,setCountryMsgAlert] = useState(false); 
  const [mobileMsgAlert,setMobileMsgAlert] = useState(false); 
  const [emailMsgAlert,setEmailMsgAlert] = useState(false); 
  const [otpMsgAlert,setOtpMsgAlert] = useState(false); 
  const [passwordMsgAlert,setPasswordMsgAlert] = useState(false);
  const [conformPasswordMsgAlert,setConformPasswordMsgAlert] = useState(false);

  const [pincodeMsgAlert,setPincodeMsgAlert] = useState(false);
  const [cityMsgAlert,setCityMsgAlert] = useState(false);

  const [fnameActive,setFnameActive] = useState(null); 
  const [agencyActive,setAgencyActive] = useState(null); 
  const [mobileActive,setMobileActive] = useState(null); 
  const [emailActive,setEmailActive] = useState(null); 
  const [otpActive,setOtpActive] = useState(null); 
  const [passwordActive,setPasswordActive] = useState(null); 
  const [conformPasswordActive,setConformPasswordActive] = useState(null); 

  const [websiteActive,setWebsiteActive] = useState(null);

  const [pincodeActive,setPincodeActive] = useState(null); 
  const [cityActive,setCityActive] = useState(null);

  const [fnameMsg,setFnameMsg] = useState('Please enter Name.'); 
  const [websiteMsg,setWebsiteMsg] = useState('Website.'); 

  const [agencyMsg,setAgencyMsg] = useState('Please enter Agency name.'); 
  const [mobileMsg,setMobileMsg] = useState('Please enter Mobile no.'); 
  const [countryMsg,setCountryMsg] = useState('Please Select Country code.'); 
  const [emailMsg,setEmailMsg] = useState('Please enter email.'); 
  const [otpMsg,setOtpMsg] = useState('Please enter Otp.'); 
  const [passwordMsg,setPasswordMsg] = useState('Password.');
  const [conformPasswordMsg,setConformPasswordMsg] = useState('Conform Password.');

  const [pincodeMsg,setPincodeMsg] = useState('Please enter pincode.');
  const [cityMsg,setCityMsg] = useState('Please enter city.');

  const [captchamsg,setCaptchamsg] = useState('Please enter captcha.'); 
  const [captchaVal,setCaptchaVal] = useState(null);
  const [succMsg,setSuccMsg] = useState(false); 
  const [captchamsgAlert,setCaptchamsgAlert] = useState(false);


  const fnameRef = useRef(null);
  const websiteRef = useRef(null);
  const agencyRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const otpRef = useRef(null);
  const passwordRef = useRef(null);
  const confpasswordRef = useRef(null);

  const pincodeRef = useRef(null);
  const cityRef = useRef(null);

  useEffect(()=>{
   
    getPagetitle();
    getIpData();
    document.addEventListener("keyup", keypressAct);
    document.addEventListener("mousedown", handleClick);
    return(()=>{
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keyup", keypressAct);
    })
  },[]);

  useEffect(()=>{
    if(succMsg==false){
      document.getElementById('email').focus();
      setCaptcha();
    }
  },[]);

  /*======= api not working date 21Dec 2021=====
  useEffect(()=>{
    if(type=='hotelier'|| type=='agent'){
      if(loginData.pincode!='0'){
        if(loginData.pincode.length==6){
          getPincode(loginData.pincode);
        }else{
          setLoginData({...loginData,city:'',state:'',country:''});
          setCityActive(null);
        }
      }
    }
  },[loginData.pincode]);*/

  useEffect(()=>{  
    fnCountryCode();
    if(type=='supp'){
      setTypeCheck('Supplier');
      setTypeName('Name of DMC / Supplier');
    }else if(type=='agent'){
      setTypeCheck('Agent');
      setTypeName('Name of agency / entity');
    }else{
      setTypeCheck('Hotelier');
      setTypeName('Hotel Name')
    }

   

  },[type]);

  const getPagetitle = ()=>{
    if(type=='supp'){
      document.title = 'Supplier Registration';
    }else if(type=='agent'){
      document.title = 'Agent Registration';
    }else{
      document.title = 'Hotelier Registration';
    }
  }
const fnCountryCode=()=>{
  const api_url = 'https://www.travsie.com/admin/';
  let formData = new FormData(); 
  formData.append('action','getcountrycode');
  return fetch(api_url+"ajax_login.php",{
    method:'POST',
    headers: {
      'Accept':'application/json',
    },
    body:formData
    }).then((response)=> response.text()).then((resData)=> {
    document.getElementById("getcountry").innerHTML=resData;
     
  })
}
  const supplierData = [
    {image:'supplier_icon_8.png', data:'Enter any target market without having a physical presence'},
    {image:'icon_7.png', data:'Sell, promote and service, all on a single platform'},
    {image:'agent_icon_7.png', data:'Digitizing your business leads to efficient processes'},
    {image:'agent_icon_8.png', data:'Predictive analytics to forcast & grow your business '},
    {image:'supplier_icon_2.png', data:'Real-time access to agents market – No old-school emails'},
    {image:'icon_6.png', data:'Business opportunities through Travsie travel agents’ network'},
  ];

  const agentData = [
    {image:'agent_icon_2.png', data:'Book faster with industry’s first “Holiday Search Engine”'},
    {image:'agent_icon_3.png', data:'No Middleman – Real time access to suppliers'},
    {image:'agent_icon_4.png', data:'Simple & Transparent, with fair pricing for all '},
    {image:'agent_icon_6.png', data:'Personalized service app for your customers'},
    {image:'agent_icon_9.png', data:'Digitizes your business - no old school email'},
    {image:'agent_icon_10.png', data:'Easier invoicing, remittances and refunds'},
  ];

  const handleClick = e => {

    if(document.getElementById('fname').value!=''){
      if (fnameRef.current && !fnameRef.current.contains(e.target)){
        setFnameActive('active'); 
      }
    }else{
      if (fnameRef.current && !fnameRef.current.contains(e.target)){
        setFnameActive(null); 
      }
    }

    if(document.getElementById('agencyName').value!=''){
      if (agencyRef.current && !agencyRef.current.contains(e.target)){
        setAgencyActive('active'); 
      }
    }else{
      if (agencyRef.current && !agencyRef.current.contains(e.target)){
        setAgencyActive(null); 
      }
    }

    if(document.getElementById('websiteName').value!=''){
      if (websiteRef.current && !websiteRef.current.contains(e.target)){
        setWebsiteActive('active'); 
      }
    }else{
      if (websiteRef.current && !websiteRef.current.contains(e.target)){
        setWebsiteActive(null); 
      }
    }

    if(document.getElementById('mobile').value!=''){
      if (mobileRef.current && !mobileRef.current.contains(e.target)){
        setMobileActive('active'); 
      }
    }else{
      if (mobileRef.current && !mobileRef.current.contains(e.target)){
        setMobileActive(null); 
      }
    }

    /*if(document.getElementById('otp').value!=''){
      if (otpRef.current && !otpRef.current.contains(e.target)){
        setOtpActive('active'); 
      }
    }else{
      if (otpRef.current && !otpRef.current.contains(e.target)){
        setOtpActive(null); 
      }
    }*/

    if(document.getElementById('email').value!=''){
      if (emailRef.current && !emailRef.current.contains(e.target)){
        setEmailActive('active'); 
      }
    }else{
      if (emailRef.current && !emailRef.current.contains(e.target)){
        setEmailActive(null); 
      }
    }

    if(type=='hotelier' || type=='agent'){
      if(document.getElementById('pincode').value!=''){
        if (pincodeRef.current && !pincodeRef.current.contains(e.target)){
          setPincodeActive('active'); 
        }
      }else{
        if (pincodeRef.current && !pincodeRef.current.contains(e.target)){
          setPincodeActive(null); 
        }
      }
  
      if(document.getElementById('city').value!=''){
        if (cityRef.current && !cityRef.current.contains(e.target)){
          setCityActive('active'); 
        }
      }else{
        if (cityRef.current && !cityRef.current.contains(e.target)){
          setCityActive(null); 
        }
      }
    }

    if(document.getElementById('password').value!=''){
      if(passwordRef.current && !passwordRef.current.contains(e.target)){
        setPasswordActive('active'); 
      }
    }else{
      if(passwordRef.current && !passwordRef.current.contains(e.target)){
        setPasswordActive(null); 
      }
    }

    if(document.getElementById('conformPassword').value!=''){
      if(confpasswordRef.current && !confpasswordRef.current.contains(e.target)){
        setConformPasswordActive('active'); 
      }
    }else{
      if(confpasswordRef.current && !confpasswordRef.current.contains(e.target)){
        setConformPasswordActive(null); 
      }
    }
  }

  const keypressAct = e => {
    if(e.keyCode===9){
      if(e.target.id=='fname'){
        setFnameActive('active'); 
      }
      
      if(e.target.id=='agencyName'){
        setAgencyActive('active'); 
      }

      if(e.target.id=='websiteName'){
        setWebsiteActive('active'); 
      }
      
      if(e.target.id=='mobile'){
        setMobileActive('active'); 
      }
      
      if(e.target.id=='email'){
        setEmailActive('active'); 
      }

      if(type=='hotelier' || type=='agent'){
        if(e.target.id=='pincode'){
          setPincodeActive('active'); 
        }

        if(e.target.id=='city'){
          setCityActive('active'); 
        }
      }
      
      /*if(e.target.id=='otp'){
        setOtpActive('active'); 
      }*/
      
      if(e.target.id=='password'){
        setPasswordActive('active'); 
      }
      
      if(e.target.id=='conformPassword'){
        setConformPasswordActive('active'); 
      }
    }
  }

  const mAction = (idd)=>{
    if(document.querySelector('#'+idd) != null) {
      if(idd=='fname'){
        if(document.querySelector('#'+idd).value=='') {
          setFnameActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setFnameActive(null);
        }
      }else if(idd=='agencyName'){
        if(document.querySelector('#'+idd).value=='') {
          setAgencyActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setAgencyActive(null);
        }
      }else if(idd=='websiteName'){
        if(document.querySelector('#'+idd).value=='') {
          setWebsiteActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setWebsiteActive(null);
        }
      }else if(idd=='pincode'){
        if(document.querySelector('#'+idd).value=='') {
          setPincodeActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setPincodeActive(null);
        }
      }else if(idd=='city'){
        if(document.querySelector('#'+idd).value=='') {
          setCityActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setCityActive(null);
        }
      }else if(idd=='mobile'){
        if(document.querySelector('#'+idd).value=='') {
          setMobileActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setMobileActive(null);
        }
      }else if(idd=='email'){
        if(document.querySelector('#'+idd).value=='') {
          setEmailActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setEmailActive(null);
        }
      }/*else if(idd=='otp'){
        if(document.querySelector('#'+idd).value=='') {
          setOtpActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setOtpActive(null);
        }
      }*/else if(idd=='password'){
        if(document.querySelector('#'+idd).value=='') {
          setPasswordActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setPasswordActive(null);
        }
      }else if(idd=='conformPassword'){
        if(document.querySelector('#'+idd).value=='') {
          setConformPasswordActive('active'); 
          document.querySelector('#'+idd).focus();
          return false;
        }else{
          setConformPasswordActive(null); 
        }
      }      
    }
  }

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  }
  
  
  const getPincode = async (data)=>{
      return await fetch('https://api.postalpincode.in/pincode/'+data,{
        method:'GET',
        headers: {
          'Accept':'application/json',
        }}).then((response)=> response.json()).then((resData)=> {
          if(resData[0]['Status']=='Success'){
            if(resData[0]['PostOffice'][0]['Block']=='' || resData[0]['PostOffice'][0]['Block']=='NA'){
              resData[0]['PostOffice'][0]['Block'] = resData[0]['PostOffice'][0]['District'];
            }
            setLoginData({...loginData,city:resData[0]['PostOffice'][0]['Block'],state:resData[0]['PostOffice'][0]['State'],country:resData[0]['PostOffice'][0]['Country']});
            setCityActive('active');
          }
      })
  }

const formSubmit = (event)=>{
  event.preventDefault();
  let valid = true;

  var getCountryval=document.getElementById("countrycode").value;
  if(loginData.name==''){
    setFnameMsgAlert(true); 
    valid = false;
    setFnameMsg('Please enter your Name.'); 
    setTimeout(()=>{
      setFnameMsgAlert(false);
    },4000);
    return false;
  }

  if(loginData.agency==''){
    setAgencyMsgAlert(true); 
    valid = false;
    setAgencyMsg(typeName); 
    setTimeout(()=>{
      setAgencyMsgAlert(false);
    },4000);
    return false;
  }

    
  if(type=='hotelier' || type=='agent'){
    if(loginData.pincode==''){
      setPincodeMsgAlert(true); 
      valid = false;
      setPincodeMsg('Please enter your pincode'); 
      setTimeout(()=>{
        setPincodeMsgAlert(false);
      },4000);
      return false;
    }else{
      let validmob = /^\d{6}$/;
      if(!validmob.test(loginData.pincode)) {
        setPincodeMsgAlert(true); 
        valid = false;
        setPincodeMsg('Please enter valid pincode'); 
        setTimeout(()=>{
          setPincodeMsgAlert(false);
        },4000);
        return false;
      }
    }

    if(loginData.city==''){
      setCityMsgAlert(true); 
      valid = false;
      setCityMsg('Please enter your city name'); 
      setTimeout(()=>{
        setCityMsgAlert(false);
      },4000);
      return false;
    }
  }

  if(getCountryval=='Country Code'){
    setCountryMsgAlert(true);
    valid = false;
    setCountryMsg('Please Select Country code.'); 
    setTimeout(()=>{
      setCountryMsgAlert(false);
    },4000);
    return false;
  }
  if(loginData.mobile==''){
    setMobileMsgAlert(true); 
    valid = false;
    setMobileMsg('Please enter your Mobile no.'); 
    setTimeout(()=>{
      setMobileMsgAlert(false);
    },4000);
    return false;
  }

  if(loginData.mobile!=null){
    var validmob = /^\d{8,10}$/;
  //  var getmob=document.getElementById("countrycode").value;
    // if(getmob=='+975'){
    //   validmob = /^\d{8}$/;
    // }
 
    if(!validmob.test(loginData.mobile)) {
      setMobileMsgAlert(true); 
      valid = false;
      setMobileMsg('Please enter valid Mobile no.'); 
      setTimeout(()=>{
        setMobileMsgAlert(false);
      },4000);
      return false;
    }
  }

  if(loginData.email==''){
    setEmailMsgAlert(true); 
    valid = false;
    setEmailMsg('Please enter your email address.'); 
    setTimeout(()=>{
      setEmailMsgAlert(false);
    },4000);
    return false;
  }

  if(loginData.email!=null){
      let emailCheck = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if(!emailCheck.test(loginData.email)) {
        setEmailMsgAlert(true);
        valid = false;
        setEmailMsg('Please enter your valid email address.'); 
          setTimeout(()=>{
            setEmailMsgAlert(false);
          },4000);
          return false;
      }
  }

 
  if(loginData.password==''){
      setPasswordMsgAlert(true);
      valid = false;
      setPasswordMsg('Your password.');
      setTimeout(()=>{
        setPasswordMsgAlert(false);
      },4000);
      return false;
  }

  if(loginData.re_password==''){
    setConformPasswordMsgAlert(true);
    valid = false;
    setConformPasswordMsg('Your conform password.');
    setTimeout(()=>{
      setConformPasswordMsgAlert(false);
    },4000);
    return false;
  }

  if(loginData.password!=loginData.re_password){
    setPasswordMsgAlert(true);
    valid = false;
    setPasswordMsg('Password & conform password must same.');
    setTimeout(()=>{
      setPasswordMsgAlert(false);
    },4000);
    return false;
  }

  if(loginData.captcha==''){
    setCaptchamsgAlert(true);
    valid = false;
    setCaptchamsg('Please enter captcha.');
    setTimeout(()=>{
      setCaptchamsgAlert(false);
    },4000);
  }else if(loginData.captcha!=captchaVal.split(" ").join("")){
    setCaptchamsgAlert(true);
    valid = false;
    setCaptchamsg('Please enter valid captcha.');
    setTimeout(()=>{
      setCaptchamsgAlert(false);
    },4000);
  }else{
    setTimeout(()=>{
      setCaptchamsgAlert(false);
    },4000);
  }

  if(valid===true){
    fireApi();
  }
}

const setCaptcha = ()=> {
  var a = Math.ceil(Math.random() * 10)+ '';
  var b = Math.ceil(Math.random() * 10)+ '';       
  var c = Math.ceil(Math.random() * 10)+ '';  
  var d = Math.ceil(Math.random() * 10)+ '';  
  var e = Math.ceil(Math.random() * 10)+ '';  
  var f = Math.ceil(Math.random() * 10)+ '';  
  for(;a>9;){a=a/10;}
  for(;b>9;){b=b/10;}
  for(;c>9;){c=c/10;}
  for(;d>9;){d=d/10;}
  for(;e>9;){e=e/10;}
  for(;f>9;){f=f/10;}
  var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' '+ f;
  var code1 = a +  b +  c + d +  e + f;
  setCaptchaVal(code);
}

const fixTopact = (type)=>{
  if(type=='fname'){
    setFnameActive('active')
  }else if(type=='agencyName'){
    setAgencyActive('active')
  }else if(type=='websiteName'){
    setWebsiteActive('active')
  }else if(type=='pincode'){
    setPincodeActive('active')
  }else if(type=='city'){
    setCityActive('active')
  }else if(type=='mobile'){
    setMobileActive('active')
  }else if(type=='email'){
    setEmailActive('active')
  }/*else if(type=='otp'){
    setOtpActive('active')
  }*/else if(type=='password'){
    setPasswordActive('active')
  }else{
    setConformPasswordActive('active')
  }
}

const resetF = ()=>{
    setFnameActive('');
    setAgencyActive('');
    setPincodeActive('');
    setCityActive('');
    setMobileActive('');
    setEmailActive('');
    //setOtpActive('')
    setPasswordActive('');
    setConformPasswordActive('');
}

const api_url = 'https://www.travsie.com/admin/';

const getIpData = ()=> {
  let formData = new FormData();  
  formData.append('action','getIP');
  return fetch(api_url+"ajax_login.php",{
    method:'POST',
    headers: {
      'Accept':'application/json',
    },
    body: formData
    }).then((response)=> response.text()).then((resData)=> {
      if(resData){
        setIP(resData)
      } 
  })
}
const history = useHistory();
const fireApi = ()=>{
  getIpData(); 
  
  let formData = new FormData();  
  var getCountryval=document.getElementById("countrycode").value;
  formData.append('name',loginData.name);
  formData.append('agency', loginData.agency);
  formData.append('mobile',loginData.mobile);
  formData.append('email', loginData.email);
  formData.append('website', loginData.website);
  formData.append('countrycode',getCountryval);
  //formData.append('otp',loginData.otp);
  formData.append('password', btoa(loginData.password));
  formData.append('re_password',btoa(loginData.re_password));
  formData.append('login_type', type);
  formData.append('action','registration');
  formData.append('ip',ip);

  if(type=='hotelier' || type=='agent'){
    formData.append('pincode',loginData.pincode);
    formData.append('city',loginData.city);
    formData.append('state',loginData.state);
    formData.append('country',loginData.country);
  }

  return fetch(api_url+"ajax_login.php",{
    method:'POST',
    headers: {
      'Accept':'application/json',
    },
    body: formData
    }).then((response)=> response.json()).then((resData)=> {

      if(resData.success){
        setLoginData({name:'',agency:'',mobile:'',email:'',otp:'',password:'',re_password:'',login_type:'',action:'',ip:'',pincode:'',city:'',state:'',country:'',website:'',countrycode:''});
        resetF();     
        setOnSuccess({flag:!onSuccess.flag,message:resData.success,action:'afterRegister'});
         history.push('/thankyou/'+ type);
       // navigate('/thankyou');
      
       
      }else{
        setMobileMsgAlert(true); 
        setMobileMsg(resData.error); 
        setTimeout(()=>{
          setMobileMsgAlert(false);
        },4000);
        
        return false;
      }
  })
}

  return (
    <section className="row_hb">
        {onSuccess.flag==true?<Popup setOnSuccess={setOnSuccess} data={onSuccess}/>:null}
        <div className="row_hb bg_col signUpCntr pad_t_30 pad_b_50">
          <div className="container">
          <div className="row_hb">
            <div className="row_hb pad_b_35 teamMobile mob_p_b_0 pos_relative index_2">
              <h2 className="row_hb txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 mob_text_align_c fnt_size_50">Be Part of the  <span className="txt_color_3">Travsie revolution</span></h2>
            </div>
            <div className="row_hb pos_relative">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_l_0 pad_r_80 mob_p_r_0 pos_relative">
                <div className="aboutAnimate moveimgBgSm signupMove index_1 pos_absolute"></div>
                <div className="row_hb loginBox mob_m_t_b_custom border_rad_8 bg_white pos_relative index_2">
                    <p className="row_hb mob_text_align_c txt_color_8 font_fam_halvetica mob_font_size_15 fnt_size_16"><span className="txt_color_3"><strong> Register as a Travsie {typeCheck}</strong></span></p>
                    <form className="row_hb mar_t_10" autoComplete="off" onSubmit={formSubmit}>

                      <div className="form-group pos_relative row_hb mar_t_15 mar_b_30"><span></span>
                        {fnameMsgAlert===true?<p className="errorMsgEl pos_absolute">{fnameMsg}</p>:null}
                        <span ref={fnameRef} onClick={()=>mAction('fname')} className={fnameActive==='active'?`pos_absolute ${fnameActive} chngElm`:"pos_absolute chngElm"}>Name</span>
                        <input type="text" onChange={handleChange} onClick={()=>fixTopact('fname')} id="fname"  value={loginData.name} className="form-control" autoComplete="new-fname" name="name"/> 
                      </div>

                      <div className="form-group pos_relative row_hb mar_t_15 mar_b_30"><span></span>
                        {agencyMsgAlert===true?<p className="errorMsgEl pos_absolute">{agencyMsg}</p>:null}
                        <span onClick={()=>mAction('agencyName')} ref={agencyRef} className={agencyActive==='active'?`pos_absolute ${agencyActive} chngElm`:"pos_absolute chngElm"}>{typeName}</span>
                        <input type="text" onChange={handleChange} onClick={()=>fixTopact('agencyName')} id="agencyName"  value={loginData.agency} className="form-control" name="agency"/> 
                      </div>

                      <div className="form-group pos_relative row_hb mar_t_15 mar_b_30"><span></span>
                        <span onClick={()=>mAction('websiteName')} ref={websiteRef} className={websiteActive==='active'?`pos_absolute ${websiteActive} chngElm`:"pos_absolute chngElm"}>{websiteMsg}</span>
                        <input type="text" onChange={handleChange} onClick={()=>fixTopact('websiteName')} id="websiteName"  value={loginData.website} className="form-control" name="website"/> 
                      </div>

                      {type=='hotelier' || type=='agent'?
                      <div className="row_hb mar_b_30">
                        <div className="col-xs-12 col-sm-6 mbx col-md-6 col-lg-3 pad_l_0 pad_r_0 mob_m_b_30">
                          <div className="form-group mar_a_0 pos_relative row_hb">
                            {pincodeMsgAlert===true?<p className="errorMsgEl pos_absolute">{pincodeMsg}</p>:null}
                            <span ref={pincodeRef} onClick={()=>mAction('pincode')} className={pincodeActive!='active'?"pos_absolute chngElm":`pos_absolute ${pincodeActive} chngElm`}>Pin Code</span>
                            <input type="text" className="form-control" maxLength="6" name="pincode" id="pincode" onClick={()=>fixTopact('pincode')} onChange={handleChange}  autoComplete="off" value={loginData.pincode}/>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 mbx col-md-6 col-lg-9 pad_r_0 mob_p_l_0">
                          <div className="form-group mar_a_0 pos_relative row_hb">
                            {cityMsgAlert===true?<p className="errorMsgEl pos_absolute">{cityMsg}</p>:null}
                            <span ref={cityRef} onClick={()=>mAction('city')} className={cityActive!='active'?"pos_absolute chngElm":`pos_absolute ${cityActive} chngElm`}>City</span>
                            <input type="text" className="form-control" name="city" id="city" onClick={()=>fixTopact('city')} onChange={handleChange}  autoComplete="off" value={loginData.city}/>
                          </div>
                        </div>
                      </div>:
                          <div>
                            <input type="hidden" maxLength="6" name="pincode" id="pincode"    autoComplete="off" value=""/>
                            <input type="hidden" name="city" id="city"   autoComplete="off" value=""/>
                          </div>
                          }


                      <div className="row_hb">
                        <div className="col-xs-12 col-sm-6 mbx col-md-6 col-lg-3 pad_l_0 pad_r_0 mob_m_b_30">
                          <div className="form-group mar_a_0 pos_relative row_hb">
                          {countryMsgAlert===true?<p className="errorMsgEl pos_absolute" style={{'width':'320px','left':'0','top':'38px'}}>{countryMsg}</p>:null}
                          <div className='row_hb' id="getcountry">
                            <select name="countrycode" id="countrycode" className="form-control" >
                                <option value="0">Country Code</option>
                                <option value="+91">+91</option>
                            </select>  
                          </div>
                                              
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 mbx col-md-6 col-lg-9 pad_r_0 mob_p_l_0">
                          <div className="form-group pos_relative row_hb mar_t_15 mar_b_30"><span></span>
                            {mobileMsgAlert===true?<p className="errorMsgEl pos_absolute">{mobileMsg}</p>:null}
                            <span ref={mobileRef} onClick={()=>mAction('mobile')} className={mobileActive==='active'?`pos_absolute ${mobileActive} chngElm`:"pos_absolute chngElm"}>Mobile</span>
                            <input type="text" onChange={handleChange} onClick={()=>fixTopact('mobile')} id="mobile"  value={loginData.mobile} className="form-control" name="mobile"/> 
                          </div>
                        </div>
                      </div>

                      <div className="row_hb mar_b_30 pos_relative">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0 mob_p_r_0">
                          <div className="form-group pos_relative mar_a_0 row_hb"><span></span>
                            {emailMsgAlert===true?<p className="errorMsgEl pos_absolute">{emailMsg}</p>:null}
                            <span ref={emailRef} onClick={()=>mAction('email')} className={emailActive==='active'?`pos_absolute ${emailActive} chngElm`:"pos_absolute chngElm"}>Email</span>
                            <input type="text" onChange={handleChange} onClick={()=>fixTopact('email')} id="email"  value={loginData.email} className="form-control" name="email"/> 
                            {/*<a onClick={fireOtp} className="getOtp txt_color_3 font_fam_halvetica fnt_size_12 pos_absolute index_2">Get OTP<i className="fa fa-caret-right pad_l_5" aria-hidden="true"></i></a>*/}
                          </div>
                        </div>

                        {/*<div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 pad_r_0 mob_p_l_0">
                          <div className="form-group pos_relative mar_a_0 row_hb"><span></span>
                            {otpMsgAlert===true?<p className="errorMsgEl pos_absolute">{otpMsg}</p>:null}
                            <span ref={otpRef} onClick={()=>mAction('otp')} className={otpActive==='active'?`pos_absolute ${otpActive} chngElm`:"pos_absolute chngElm"}>Enter Otp</span>
                            <input type="text" onChange={handleChange} onClick={()=>fixTopact('otp')} id="otp"  value={loginData.otp} className="form-control" name="otp"/> 
                          </div>
                        </div>
  {otpSendMsg.flag===true?<p className={otpSendMsg.type=='success'?"successMsgEEt pos_absolute":"errorMsgEl pos_absolute"}>{otpSendMsg.data}</p>:null}*/}
                      </div>
                      

                      <div className="row_hb mar_b_20">
                        <div className="col-xs-12 col-sm-6 mbx col-md-6 col-lg-6 pad_l_0 pad_r_0 mob_m_b_30">
                          <div className="form-group mar_a_0 pos_relative row_hb">
                            {passwordMsgAlert===true?<p className="errorMsgEl pos_absolute">{passwordMsg}</p>:null}
                            <span ref={passwordRef} onClick={()=>mAction('password')} className={passwordActive!='active'?"pos_absolute chngElm":`pos_absolute ${passwordActive} chngElm`}>Set Password</span>
                            <input type="password" className="form-control" name="password" id="password" onClick={()=>fixTopact('password')} onChange={handleChange}  autoComplete="off" value={loginData.password}/>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-6 mbx col-md-6 col-lg-6 pad_r_0 mob_p_l_0">
                          <div className="form-group mar_a_0 pos_relative row_hb">
                            {conformPasswordMsgAlert===true?<p className="errorMsgEl pos_absolute">{conformPasswordMsg}</p>:null}
                            <span ref={confpasswordRef} onClick={()=>mAction('conformPassword')} className={conformPasswordActive!='active'?"pos_absolute chngElm":`pos_absolute ${conformPasswordActive} chngElm`}>Re-enter Password</span>
                            <input type="password" className="form-control" name="re_password" id="conformPassword" onClick={()=>fixTopact('conformPassword')} onChange={handleChange}  autoComplete="off" value={loginData.re_password}/>
                          </div>
                        </div>
                      </div>

                      <div className="row_hb txcapt">
                        <span className="row_hb txtDesElm">Please enter code</span>
                        <div className="deskWith">
                          <div className="capchaleft">
                            <span className="txtCaptcha">{captchaVal}</span>
                            <a href="javascript:void(0);" onClick={setCaptcha} id="btnrefresh" style={{color:'#000',fontSize: '12px'}}><i className="fa fa-refresh" aria-hidden="true" style={{color:'#fc901b',fontSize: '18px', padding: '11px 6px'}}></i></a>  
                          </div>
                          <div className="capchaleft last">
                            <input type="text" name="captcha" maxLength="6" onChange={handleChange}  autoComplete="off" value={loginData.captcha} className="form-control"/>
                          </div>

                          <div className="row_hb pos_relative">
                            {captchamsgAlert===true?<p className="row_hb captchError pos_absolute">{captchamsg}</p>:null}
                          </div>
                        </div>
                        <button className="btn fnt_size_13 bg_light_blue txt_color_4 btn_hov pad_a_8 pull-right">Register Me <i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></button>

                      </div>
                    </form>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_r_0 mob_p_l_0">
                <div className="row_hb">
                  <div className="row_hb pad_b_35 mob_text_align_c">

                      {type=='supp' || type=='hotelier'?<h2 className="row_hb txt_color_3 fnt_weight_700 mar_a_0 pad_a_0 text_align_c fnt_size_18">Connect with agents without physical presence! </h2>:<h2 className="row_hb txt_color_3 fnt_weight_700 mar_a_0 pad_a_0 text_align_c fnt_size_18">Connect directly with suppliers & book holidays for &#x20B9;150 or less! </h2>}

                      <p className="row_hb mar_a_0 pad_t_5 fnt_size_13 text_align_c">And grow your business faster with Travsie’s revolutionary features</p>
                  </div>

                  {type=='supp' || type=='hotelier'?
                  <div className="row_hb">
                    {supplierData.length>0?
                      <div className="row_hb">
                          {supplierData.map((item,indx)=>
                          <div key={`team_${indx}`} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pad_l_0">
                            <div className={indx>3?"mar_b_0 row_hb connectRegBox mob_min_ht border_rad_8 text_align_c pos_relative":`row_hb connectRegBox mob_min_ht border_rad_8 text_align_c pos_relative`}>
                                <img src={`${window.location.origin}/images/${item.image}`} alt=""/>
                                <p className="font_fam_halvetica row_hb fnt_size_13 txt_color_8 line_height_20"> {item.data}</p>
                            </div>
                          </div>
                          )}
                      </div>
                    :null}
                  </div>
                  :
                  <div className="row_hb">
                    {agentData.length>0?
                      <div className="row_hb">
                          {agentData.map((item,indx)=>
                          <div key={`team_${indx}`} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pad_l_0">
                            <div className={indx>3?"mar_b_0 row_hb connectRegBox mob_min_ht border_rad_8 text_align_c pos_relative":`row_hb connectRegBox mob_min_ht border_rad_8 text_align_c pos_relative`}>
                                <img src={`${window.location.origin}/images/${item.image}`} alt=""/>
                                <p className="font_fam_halvetica row_hb fnt_size_13 txt_color_8 line_height_20"> {item.data}</p>
                            </div>
                          </div>
                          )}
                      </div>
                    :null}
                  </div>
                  }
                </div>
              </div>  
            </div>
          </div>
      </div>
        </div>
    </section>
  );
}

export default Signup;