import { Link } from 'react-router-dom';
function Readmore() {
  return (
    <div className="blogLeft">
        <h5 className="font_fam_roboto">Read More</h5>
        <ul className="row_hb font_fam_roboto">
          <li><Link to="/blog1">Unpopular Opinion </Link></li>
          <li><Link to="/blog2">Revenge Travel </Link></li>
          <li><Link to="/blog3">Challenges </Link></li>
          <li><Link to="/blog4">Suppliers Scared </Link></li>
          <li><Link to="/blog5">Package Pricing </Link></li>
          <li><Link to="/blog6">Holy Grail </Link></li>
          <li><Link to="/blog7">Travsie, a B2B Platform </Link></li>
        </ul>
      </div>
  );
}

export default Readmore;