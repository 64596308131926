import React,{ useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import Common from '../common_component/Common';
import * as animationjs from '../../animation';

import imgPath_mov from '../../images/scale_img.mov';
import holiday_bg from '../../images/holiday_bg.gif';
import map from '../../images/map.png';
import mobile_scrn from '../../images/mobile_scrn.png';
import left_icon from '../../images/left_icon.png';
import right_icon from '../../images/right_icon.png';
import caretIcon from '../../images/caretIcon.png';

import news1 from '../../images/news_1.png';
import news2 from '../../images/news_2.png';
import news3 from '../../images/news_3.png';
import news4 from '../../images/news_4.png';
import news5 from '../../images/news_5.png';

import './Register.css';

function Register() {
  const [homeUrl,setHomeUrl] = useState(true);
  const [getScrnWidth,setGetScrnWidth] = useState(false);
  const [teamData,setTeamData] = useState({testiData:null});
  const api_url = 'https://www.travsie.com/admin/';

  useEffect(()=>{
    getPagetitle();
    getTestimonial();
    
    if(window.innerWidth>767){
      moveActive1('benefitsBox');
      setGetScrnWidth(true);
    }else{
      setGetScrnWidth(false);
    }

    if(document.querySelector('#benefitsInner')!=null){     
      document.querySelector('#benefitsInner').addEventListener("mouseenter", insideElm); 
      document.querySelector('#benefitsInner').addEventListener("mouseleave", outsideElm);
    } 
  },[]);


let rmvTimer1;
let countNo = 0;
function moveActive1(cls){ 
  let liElm = document.querySelectorAll('.'+cls+' li');  
  if(countNo<liElm.length){
        if(document.querySelector('.'+cls+' li.active') != null){
          document.querySelector('.'+cls+' li.active').classList.remove('active');
        }
        liElm[countNo].classList.add('active');  
        countNo++;
      }


 if(countNo<liElm.length){  
  rmvTimer1 = setTimeout(function(){
    moveActive1("benefitsBox");
      },2000);
 }
else{	   
    countNo= 0;  
    rmvTimer1 =  setTimeout(function(){
      moveActive1("benefitsBox");
  },2000);
    
}
}
const outsideElm = (e) => {  
  moveActive1("benefitsBox");
}

const insideElm = (e) => {
    clearTimeout(rmvTimer1);   
}




  const loginData = [
    {image:'agent1.png', data:'I am an Agent',link:'/signup/agent'},
    {image:'agent2.png', data:'I am a Supplier',link:'/signup/supp'},
    {image:'agent3.png', data:'I am a Hotelier',link:'/signup/hotelier'},
  ];

  const getPagetitle = ()=>{
    document.title = 'Travsie';
  }

  const addAniClass = (cls,n)=>{
    if(window.innerWidth>767){
      animationjs.addAniClass(cls,n);
    }
    else{
      if (document.querySelector('.'+cls) != null) {
        if(cls=='ourSolution'){
          if(n=='0'){
            setTimeout(()=>{animationjs.moveActiveMobile('customizeBox')},3200);
          }
        }
      }
    }
  }

 

  const removeAniClass = (cls)=>{
    if(window.innerWidth>767){
      animationjs.removeAniClass(cls);
    }
  }

  const getTestimonial  = async()=>{
    try{
      let formData = new FormData();  
      formData.append('action','getTestimonials');
      return await fetch(api_url+`ajax_login.php`,{
        method:'POST',
        headers: {
          'Accept':'application/json',
        },
        body: formData
      }).then((response)=> response.json()).then((resData)=> {
        if(resData.success){
          setTeamData({...teamData,testiData:resData.success});
        }
      })
    }catch(e){
      console.log(e);
    }
    
  }

  const getRating = (rating)=>{
    var star_rating = [];
      for (var i = 1; i<=rating; i++){
        star_rating.push(<i style={{fontSize:'20px',color:'#ff8f00',margin:'0 3px'}} className="fa fa-star" aria-hidden="true"></i>);
      }
    return star_rating;
  }

  return (
    <section className="row_hb holidaySCntr pad_t_50">

      <div className="row_hb">
        <div className="container">
          <div className="row_hb">
            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 pad_l_0 mob_p_r_0">
                <div className="row_hb teamMobile">
                  <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 mob_text_align_c fnt_size_50 line_height_60">Creating Business Opportunity for <span className="line_height_82 txt_color_3 display_block">Holiday Sellers</span></h2>
                  
                  {getScrnWidth==false?<div className="row_hb">
                    <video className="row_hb" x-webkit-airplay="allow" defaultmuted="defaultmuted" muted="muted" playsInline="" autoPlay="autoplay" loop="loop">
                      <source src={imgPath_mov} type="video/mp4" />
                    </video>
                  </div>:null}
                  
                  <p className="mob_row_hb fnt_size_22 mar_a_0 pad_t_20 pad_b_20 font_fam_halvetica mob_p_r_0">First B2B holiday sourcing platform facilitating seamless connectivity between travel agents and global suppliers and creating business opportunities for last mile travel agent in India through value added services.</p>
                 
                
                </div>
            </div>

            {getScrnWidth==true?<div className="col-xs-12 col-sm-7 col-md-7 col-lg-7 pad_r_0">
                <video style={{width:'92%',float:'right'}} x-webkit-airplay="allow" defaultmuted="defaultmuted" muted="muted" playsInline="" autoPlay="autoplay" loop="loop">
                  <source src={imgPath_mov} type="video/mp4" />
                </video>
            </div>:null}

          </div>
        </div>
      </div>
      <div className="row_hb pad_t_50 pad_b_30 bg_col">                  
        <div className="container pos_relative">
        <div className={`aboutAnimate moveimgBgSmBnft agentEl agentR index_1 pos_absolute`}></div>
          <div className="row_hb text_align_c pos_relative index_2">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0 ">
            
                <div className="row_hb teamMobile">

                  <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50"><span className="txt_color_3">Register </span> Now   </h2>
                  <p className="fnt_size_22 mar_a_0 pad_t_25 mob_p_t_15 font_fam_halvetica mob_d_none pad_l_r_120">The most customizable solutions to digitize your sourcing, <br/>sales and service processes </p>
                  <p className="fnt_size_22 mar_a_0 pad_t_25 mob_p_t_15 font_fam_halvetica mob_d_block display_none pad_l_r_120">The most customizable solutions to digitize your sourcing, sales and service processes  </p>
                </div>
            </div>
            <div className="col-sm-12 pad_l_0 pad_r_0 ">
           
                <div className="row_hb text_align_c">
                
                  {loginData.length>0?
                    <div className="row_hb dflex-sm mar_t_20">
                        {loginData.map((item,indx)=>
                        <div key={`team_${indx}`} className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                          <div className="row_hb agentBox border_rad_8 mob_min_ht text_align_c pad_a_20 pos_relative">
                         
                              <img className="mar_t_15" src={`${window.location.origin}/images/${item.image}`} alt=""/>
                              <p className="font_fam_halvetica row_hb fnt_size_14 line_height_22"> {item.data}</p>
                              <Link className="font_fam_halvetica pribtn row_hb fnt_size_14 line_height_22" to={item.link} >Register Now</Link>
                            
                          </div>
                        </div>
                        )}
                    </div>
                  :null}
                </div>
            </div>
          </div>
        </div>
      </div>
      <Common homeData={homeUrl}/>

      <div className="row_hb pad_t_70 m_p_t_50 bg_col">
        <Waypoint onEnter={()=>addAniClass('ourSolution','0')} onLeave={()=>removeAniClass('ourSolution')}>
        <div className="container">
          <div className="row_hb ourSolution">
            <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 pad_l_0 mob_p_r_0">
                <div className="row_hb pad_t_180 mob_p_t_0 mob_p_b_15 slideInLeftElm slow mob_text_align_c animated teamMobile">
                  <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">Our <span className="txt_color_3 mob_d_block">Solution</span></h2>
                  <p className="fnt_size_22 mar_a_0 pad_t_20 pad_b_20 mob_p_t_15 font_fam_halvetica pad_r_10">We have resolved core challenges that <br/>travel agents and suppliers face - right <br/>from sourcing to servicing.</p>
                </div>
            </div>

            <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7 pos_relative pad_r_0 mob_p_l_0">
                <div className="row_hb">
                  <img className="whSet mob_d_none mob_pos_unset mob_mid_aligne1 slideInRightElm animated slow delay-1s" src={mobile_scrn} alt=""/>
                  <ul className="customizeBox mob_p_t_15 zoomInUpElm animated slower delay-1s mar_l_70 pos_relative index_2 mar_b_0">
                    <li className="active">
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText left">Customization </span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText right">Direct contracting <br/>with global Suppliers </span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText left">Price parity</span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText right">Resolves <br/>operational issues </span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText left">Facilitate <br/>Payments </span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText right">Service app <br/>to scale business </span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText left">Transparency</span>
                    </li>

                    <li>
                      <span className="pole"></span>
                      <span className="topDot"></span>
                      <span className="descText right">New business<br/> opportunities</span>
                    </li>
                  </ul>
                </div>
            </div>

          </div>
        </div>
        </Waypoint>
      </div>
       
      <div className="row_hb pad_t_30 pad_b_70">
        <div className="container">
          <div className="row_hb">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_l_0 mob_p_r_0">
                <div className="row_hb disp_flex pad_t_25 mob_p_t_0">

                  <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                    <div className="row_hb pos_relative">
                        <div className="aboutAnimate moveimgBgSm howWeElm index_1 pos_absolute"></div>
                        <div className="row_hb text_align_c minht_130">
                            <img className="mob_m_t_b_25 mar_t_30" src={right_icon} alt=""/>
                        </div>
                        <div className="row_hb mar_t_30 pad_a_25 bg_what_how min_ht_480 mob_min_ht border_rad_8 ">
                          <div className="row_hb teamMobile">
                            <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50 text_align_c">What we <span className="line_height_82 txt_color_3">do</span></h2>
                          </div>

                          <ul className="row_hb mar_a_0 doULElm mob_p_l_0 mob_p_r_0 pos_relative index_2">
                            <li className="row_hb pad_b_20 mob_p_b_15">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">We enable and empower the supplier-agent relationship</span>
                            </li>
                            <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">We optimize business processes between travel agents and global suppliers – from sourcing to servicing</span>
                            </li>
                            <li className="row_hb pad_b_20 mob_p_b_15">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">A B2B marketplace to streamline transactions between travel agents and suppliers</span>
                            </li>
                            <li className="row_hb">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">We build relationships with global travel partners, while our platform takes care of their business needs. </span>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>

                </div>
            </div>
          
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_l_0 mob_p_r_0">
                <div className="row_hb disp_flex pad_t_25 mob_p_t_0">

                  <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                    <div className="row_hb pos_relative">
                        <div className="aboutAnimate moveimgBgSm last index_1 pos_absolute"></div>
                        <div className="row_hb text_align_c minht_130">
                            <img className="mob_m_t_b_25 mob_m_t_b_25_1024"  src={left_icon} alt=""/>
                        </div>
                        <div className="row_hb mar_t_30 pad_a_25 bg_what_how min_ht_480 mob_min_ht border_rad_8 ">
                          <div className="row_hb teamMobile">
                            <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50 text_align_c">How we <span className="line_height_82 txt_color_3">do it</span></h2>
                          </div>
                          <ul className="row_hb mar_a_0 doULElm mob_p_l_0 mob_p_r_0 pos_relative index_2">
                            <li className="row_hb pad_b_20 mob_p_b_15">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">Through our seamless, multi-channel & multi-functional “Holiday Search Engine”</span>
                            </li>
                            <li className="row_hb pad_b_20 mob_p_b_15">
                              <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">Delivering single step customization</span>
                            </li>
                            <li className="row_hb pad_b_20 mob_p_b_15">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">Creating a hybrid marketplace, with industry-first value added services</span>
                            </li>
                            <li className="row_hb pad_b_20 mob_p_b_15">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">Eliminating the Middleman </span>
                            </li>

                            <li className="row_hb">
                                <img src={caretIcon} alt=""/>
                                <span className="font_fam_halvetica fnt_size_15">Via our service app that helps scale business</span>
                            </li>

                          </ul>
                        
                        </div>
                    </div>
                  </div>

                </div>
            </div>
          
          </div>
        </div>
      </div>

      <div className="row_hb pad_t_70 pad_b_70 bg_col m_p_t_b_50">
        <Waypoint onEnter={()=>addAniClass('theBenefits','0')} onLeave={()=>removeAniClass('theBenefits')}>
          <div className="container">
          <div className="row_hb theBenefits">
            <div className="row_hb text_align_c teamMobile mob_text_align_c">
              <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50 line_height_60">The benefits  <span className="txt_color_3 ">we deliver</span></h2>
            </div>
              <div className="row_hb mob_d_none">
                <ul id="benefitsInner" className="row_hb benefitsBox benefitsBox slideInRightElm slower animated pos_relative">
                  <li className="active">
                    <span className="pole"></span>
                    <span className="startDot"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Digitizing small <br/>and medium-size <br/>businesses </span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_3"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Real time <br/>quotes from <br/>global suppliers  </span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_2"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Direct access & transactions with <br/>global suppliers</span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_1"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Ease of <br/>doing business<br/> No emails </span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_4"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Wide choice of products and value-added services</span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_5"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Efficient, digital booking & communication process</span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_6"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Higher volumes <br/>for agents and<br/> suppliers</span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_8"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Integrated <br/>invoicing and<br/> voucher tool</span>
                  </li>

                  <li>
                    <span className="pole"></span>
                    <span className="endDot"></span>
                    <span className="topDot"></span>
                    <span className="topicon icon_7"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Long-term <br/>profitability and<br/> business stability </span>
                  </li>
                </ul>
              </div>

              <div className="row_hb mob_d_block display_none">
                <ul className="row_hb benefitsBoxNew ">
                  <li>
                    <span className="topicon icon"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Digitizing small and medium-size businesses </span>
                  </li>

                  <li>
                    <span className="topicon icon_3"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Real time quotes from global suppliers </span>
                  </li>

                  <li>
                    <span className="topicon icon_2"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Direct access & transactions with global suppliers</span>
                  </li>

                  <li>
                    <span className="topicon icon_1"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Ease of doing business No emails</span>
                  </li>

                  <li>
                    <span className="topicon icon_4"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Wide choice of products and value-added services</span>
                  </li>

                  <li>
                    <span className="topicon icon_5"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Efficient, digital booking & communication process</span>
                  </li>

                  <li>
                    <span className="topicon icon_6"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Higher volumes for agents and suppliers</span>
                  </li>

                  <li>
                    <span className="topicon icon_8"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Integrated invoicing and voucher tool</span>
                  </li>

                  <li>
                    <span className="topicon icon_7"></span>
                    <span className="descText bottom text_align_c font_fam_halvetica">Long-term profitability and business stability</span>
                  </li>
                </ul>
              </div>
          </div>
        </div>
        </Waypoint>
      </div>
     
      <div className="row_hb">
        <Waypoint onEnter={()=>addAniClass('foresee','1')} onLeave={()=>removeAniClass('foresee')}>
          <div className="row_hb foresee pos_relative overf_hid">
            <img className="rightFixImg slideInRightElm slow delay-2s animated" src={map} alt=""/>
            <div className="container">
              <div className="row_hb pad_t_70 pad_b_70 m_p_t_b_50">
                <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 pad_l_0 mob_p_r_0">
                    <div className="row_hb pad_t_25 slideInLeftElm slow animated teamMobile">
                      <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 mob_text_align_c fnt_size_50 line_height_60">How we foresee  <span className="txt_color_3 display_block">ourselves</span></h2>
                      <p className="fnt_size_22 mar_a_0 pad_t_20 mob_p_t_15 pad_b_20 font_fam_halvetica pad_r_10">We are aiming to build and scale up to a “One World - One Platform” for the holiday selling ecosystem. We are constantly evolving and innovating our products. To help new product innovation in line with business in new normal, we will be adding new destination, suppliers, products and much more…</p>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7 pad_r_0 mob_p_l_0">
                    <div className="row_hb text_align_r">
                        <img className="holiImg aniIElm mobsize" src={holiday_bg} alt=""/>
                    </div>
                </div>
              </div>
            </div>
            </div>
        </Waypoint>
      </div>

      {/*{teamData.testiData!=null?
        <div className="row_hb pad_t_70 pad_b_70 teamMobile mob_p_b_0 mob_p_t_35 bg_col">
          <div className="row_hb theBenefits">
            <div className="container">
              <div className="row_hb">
                <div className="row_hb">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0">
                    <div className="row_hb text_align_c">
                      <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">User  <span className="txt_color_3">Experience</span></h2>
                      <p className="row_hb pad_t_20 pad_b_20 fnt_size_20" style={{display:'none'}}></p>
                    </div>
                  </div>
                </div>
              
                <div className="row_hb pad_t_50 mob_p_t_25 mobViewelm" style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                    {teamData.testiData.map((item,indx)=>
                    <div key={`team_${indx}`} className="col-xs-12 col-sm-3 col-md-3 col-lg-3 ">
                      <div className="row_hb teamBox border_rad_8 text_align_c pad_a_20 pos_relative" style={{minHeight:'150px'}}>

                          {item.imagepath!=''?
                            <span className={`aboutComp`} style={{height:'90px'}}>
                              <img style={{width:'60px',borderRadius:'5px'}} src={api_url+'uploads/testimonials/'+item.imagepath}  alt="Travsie"/>
                            </span>
                          :
                            <span className={`aboutComp`} style={{height:'90px',overflow:'hidden'}}>
                              <img style={{width:'75px',borderRadius:'5px'}} src={api_url+'uploads/testimonials/avatar5.png'}  alt="Travsie"/>
                            </span>
                          }

                          <h4 className="row_hb txt_color_5 fnt_size_15 line_height_24">{item.name} <span className="font_fam_halvetica fnt_size_15 display_block fnt_weight_300 txt_color_8">{item.city}</span></h4>
                          <p className="row_hb fnt_size_13 line_height_22">{getRating(item.star_rating)}</p>
                          <p className="row_hb fnt_size_13 line_height_22">{item.testimonial}</p>
                      </div>
                    </div>
                    )}
                </div>
              
              </div>
            </div>
          </div>
        </div>
      :null}*/}

      <div className="row_hb newsCntr" style={{paddingTop:'50px'}}>
        <div className="container pad_b_45 bor_b mar_b_15">
            <h2 className="row_hb mar_b_25 txt_color_5 fnt_weight_800 mar_a_0 text-center pad_a_0 fnt_size_35">Travsie <span className="txt_color_3 mob_d_block">in the News</span></h2>
            <ul className="row_hb newsBox">
                <li><a href="https://travel.economictimes.indiatimes.com/news/technology/travsie-launches-industrys-first-b2b-holiday-sourcing-platform-with-over-1100-agents-and-suppliers-on-board-receives-seed-funding/88311354" target="_blank"><img src={news1} alt=""/></a></li>
                <li><a href="https://www.outlookindia.com/website/story/outlook-spotlight-travsie-launches-industrys-first-b2b-holiday-sourcing-platform-with-1100-agents-and-suppliers-on-board-receives-/406423" target="_blank"><img src={news2} alt=""/></a></li>
                <li><a href="https://theprint.in/ani-press-releases/travsie-launches-industrys-first-b2b-holiday-sourcing-platform-with-1100-agents-and-suppliers-on-board-receives-seed-funding/782120/" target="_blank"><img src={news3} alt=""/></a></li>
                <li><a href="https://www.business-standard.com/content/press-releases-ani/travsie-launches-industry-s-first-b2b-holiday-sourcing-platform-with-1100-agents-and-suppliers-on-board-receives-seed-funding-121121501030_1.html" target="_blank"><img src={news4} alt=""/></a></li>
                <li><a href="https://www.mid-day.com/brand-media/article/travsie-launches-industry-first-b2b-holiday-sourcing-platform-23205888" target="_blank"><img src={news5} alt=""/></a></li>
            </ul>
        </div>
      </div>
    </section>
  );
}

export default Register;