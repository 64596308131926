import React,{ useState, useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';
import Popup from '../popup_component/Popup';

import './Blogs.css';

function Blogs() {
  const [onSuccess,setOnSuccess] = useState({flag:false,message:'',action:''});
  const [toggleAct,setToggleAct] = useState('all');

  useEffect(()=>{
    getPagetitle();
  },[])

  const blogsJson = [
    {image:'blog_1_small.jpg', title:'UNPOPULAR OPINION: Pandemic’s Travel Pause was a Good Thing'},
    {image:'blog_2_small.jpg', title:'Revenge Travel is coming – is the holiday industry ready?'},
    {image:'blog_3_small.jpg', title:'Dil Maange More: Challenges a holiday agent faces!'},
    {image:'blog_4_small.jpg', title:'Are Holiday Suppliers scared of going big?'},
    {image:'blog_5_small.jpg', title:"Holiday Package Pricing: A snake that eats it's own tail?"},
    {image:'blog_6_small.jpg', title:'What is the Holy grail of the holiday industry?'},
    {image:'blog_7_small.jpg', title:'Travsie, a B2B Platform'}
  ];

  const videoJson = [
    {image:'video_icon_0.jpg',url:'https://www.youtube.com/watch?v=7Lhz8ir7hs4', title:'Travsie - Building something powerful, wonderful, and digital… together.'},
    {image:'video_icon_3.jpg',url:'https://www.youtube.com/watch?v=aDi9-BIYy2g', title:'Travsie Business Model (Hindi) | Travsie.com'},
    {image:'video_icon_4.jpg',url:'https://www.youtube.com/watch?v=eA5KahE2u0U', title:'Travsie Business Model (English) | Travsie.com'},
    {image:'video_icon_1.jpg',url:'https://www.youtube.com/watch?v=A_Jbuhh8IMc', title:'The Holiday Evolution Part 1 | Travsie.com'},
    {image:'video_icon_2.jpg',url:'https://www.youtube.com/watch?v=N23bmkcyujo', title:'The Holiday Evolution Part 2 | Travsie.com'},
  ];

  const getPagetitle = ()=>{
    document.title = 'Blogs';
  }

  const showPopup = (url)=>{
    setOnSuccess({flag:!onSuccess.flag,message:url,action:'videoLink'});  
  }

  const videoToggle = (type)=>{
      setToggleAct(type);

      if(document.querySelector('.commCls.active') != null){
        document.querySelector('.commCls.active').classList.remove('active');
      }
      document.querySelector("#"+type).classList.add('active');
  }

  return (
    <section className="row_hb">
        {onSuccess.flag==true?<Popup setOnSuccess={setOnSuccess} data={onSuccess}/>:null}
        <div className="row_hb signUpCntr blogs mob_p_t_25 pad_t_50 pad_b_10">
          <div className="container">
            <div className="row_hb">
              <div className="row_hb pad_b_35 mob_p_b_10 teamMobile text_align_c">
                <h2 className="row_hb txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 mob_text_align_c fnt_size_50">Travsie <span className="txt_color_3"> Blogs</span></h2>
                <p className="row_hb txt_color_8 display_none fnt_size_20 mar_t_5 mob_m_t_0_ font_fam_halvetica">One line text comes here</p>

                <div className="row_hb">
                  <div className="row_hb mar_t_15 mob_m_b_5">
                    <a id="all" onClick={()=>videoToggle('all')} className="btn commCls active btnonHov blogHov fnt_weight_600 btn-sm mob_pad_l_r_20 txt_color_3 mob_m_r_10 mar_r_20 text_align_c border_rad_8 fnt_size_12">All<i className="fa display_none fa-times mar_l_5" aria-hidden="true"></i></a>
                    <a id="blogs" onClick={()=>videoToggle('blogs')} className="btn commCls btnonHov blogHov fnt_weight_600 btn-sm mob_pad_l_r_20 txt_color_3 mob_m_r_10 mar_r_20 text_align_c border_rad_8 fnt_size_12">Blogs<i className="fa display_none fa-times pad_l_10" aria-hidden="true"></i></a>
                    <a id="video" onClick={()=>videoToggle('video')} className="btn commCls btnonHov blogHov fnt_weight_600 btn-sm mob_pad_l_r_20 txt_color_3 text_align_c border_rad_8 fnt_size_12">Videos<i className="fa display_none fa-times pad_l_10" aria-hidden="true"></i></a>
                  </div>

                </div>
              </div>
              
              {toggleAct=='all'?<div className="row_hb mar_b_20">
                {blogsJson.length>0?
                  <div className="row_hb">
                     <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mpb_p_l_0 mob_p_l_0">
                        {videoJson.map((item,indx)=>
                          <div key={`team_${indx}`} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mob_p_r_0">
                            <div className={`row_hb travsieBlogs mar_b_35 mob_min_ht border_rad_8 mob_m_b_40`}>
                                <a onClick={()=>showPopup(item.url)}>
                                  <img src={`${window.location.origin}/images/${item.image}`} alt=""/>
                                  <div className="row_hb descArea">
                                    <h4 className="txt_color_3 fnt_size_15 row_hb mar_t_0 font_fam_halvetica line_height_22">{item.title}</h4>
                                    <div className="row_hb txt_color_3 fnt_weight_600 fnt_size_14">
                                      <a onClick={()=>showPopup(item.url)}>Play Video<i class="fa fa-angle-right pad_l_10" aria-hidden="true"></i></a>
                                    </div>
                                  </div>
                                </a>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_r_0 mob_p_l_0">
                        {blogsJson.map((item,indx)=>
                        <div key={`team_${indx}`} className="col-xs-12 col-sm-4 col-md-4 bList col-lg-4 mob_p_r_15">
                          <div className={`row_hb travsieBlogs mar_b_35 mob_min_ht border_rad_8 mob_m_b_40`}>
                            <Link to={`blog${indx+1}`}>
                              <img src={`${window.location.origin}/images/${item.image}`} alt=""/>
                              <div className="row_hb descArea">
                                <h4 className="txt_color_3 fnt_size_15 row_hb mar_t_0 font_fam_halvetica line_height_22">{item.title}</h4>
                                <div className="row_hb txt_color_3 fnt_weight_600 fnt_size_14">
                                  <Link to={`blog${indx+1}`}>Read<i class="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                                </div>
                              </div>
                              </Link>
                          </div>
                        </div>
                        )}
                      </div>

                     
                  </div>
                :null}
              </div>:null}

              {toggleAct=='blogs'?<div className="row_hb mar_b_20">
                {blogsJson.length>0?
                  <div className="row_hb">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mob_p_l_0">
                        {blogsJson.map((item,indx)=>
                        <div key={`team_${indx}`} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mob_p_r_0">
                          <div className={`row_hb travsieBlogs mar_b_35 mob_min_ht border_rad_8 mob_m_b_40`}>
                            <Link to={`blog${indx+1}`}>
                              <img src={`${window.location.origin}/images/${item.image}`} alt=""/>
                              <div className="row_hb descArea">
                                <h4 className="txt_color_3 fnt_size_15 row_hb mar_t_0 font_fam_halvetica line_height_22">{item.title}</h4>
                                <div className="row_hb txt_color_3 fnt_weight_600 fnt_size_14">
                                  <Link to={`blog${indx+1}`}>Read<i class="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                                </div>
                              </div>
                              </Link>
                          </div>
                        </div>
                        )}
                      </div>
                  </div>
                :null}
              </div>:null}

              {toggleAct=='video'?<div className="row_hb mar_b_20">
                {blogsJson.length>0?
                  <div className="row_hb">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mob_p_l_0">
                        {videoJson.map((item,indx)=>
                          <div key={`team_${indx}`} className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mob_p_r_0">
                            <div className={`row_hb travsieBlogs mar_b_35 mob_min_ht border_rad_8 mob_m_b_40`}>
                                <a onClick={()=>showPopup(item.url)}>
                                  <img src={`${window.location.origin}/images/${item.image}`} alt=""/>
                                  <div className="row_hb descArea">
                                    <h4 className="txt_color_3 fnt_size_15 row_hb mar_t_0 font_fam_halvetica line_height_22">{item.title}</h4>
                                    <div className="row_hb txt_color_3 fnt_weight_600 fnt_size_14">
                                      <a onClick={()=>showPopup(item.url)}>Play Video<i class="fa fa-angle-right pad_l_10" aria-hidden="true"></i></a>
                                    </div>
                                  </div>
                                </a>
                            </div>
                          </div>
                        )}
                      </div>
                  </div>
                :null}
              </div>:null}
            
            </div>
          </div>
        </div>
    </section>
  );
}

export default Blogs;