import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Unsubscribe.css';

function Unsubscribe() {
  const [scrolled,setScrolled] = useState(false);
  const [getWidth,setGetWidth] = useState('forDesktop');
  const [getAdd,setGetAdd] = useState('');
  const api_url = 'https://www.travsie.com/admin/';
  // const api_url = 'http://192.168.0.5/www.travsie.com/admin/';

  useEffect(() => {

    if(window.innerWidth<768){
      setGetWidth('forMobile');
    }else{
      setGetWidth('forDesktop');
    }

    getPagetitle();
    window.addEventListener('scroll',handleScroll);
    return(()=>{
      window.removeEventListener('scroll',handleScroll);
    })
  });

  const getPagetitle = ()=>{
    document.title = 'Unsubscribe';
  }

  const handleScroll = ()=>{
    window.scrollY >50?setScrolled(true):setScrolled(false);
    if(window.scrollY>4950){
      setScrolled(false);
    }

    if(window.innerWidth<768){
      setScrolled(true);
    }
  }

  const scrolltoElm = (activeId,idd)=>{
    let scrollDiv = document.querySelector("#"+idd).offsetTop-95;
    if(document.querySelector('.parentUlElm a.active') != null){
      document.querySelector('.parentUlElm a.active').classList.remove('active');
    }
    document.querySelector("#"+activeId).classList.add('active');
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    getActivate();
  }

  const getActivate = ()=>{
    getAdd==''?setGetAdd('active'):setGetAdd('');
  }

  const formSubmit = (event)=>{
    event.preventDefault();
    let valid = true;  
    var getname=document.getElementById('fname').value;    
    var getemail=document.getElementById('email').value;
    var getmob=document.getElementById('mobile').value; 
    var getlenmob=getmob.length;
    document.getElementById('msgnote').style.display='none';     
    if(getname==''){ 
      document.getElementById('errorname').style.display='block';
      setTimeout(()=>{
        document.getElementById('errorname').style.display='none';           
     },4000);
      valid=false;
      return false;   
    }
    
    if(getemail==''){ 
      document.getElementById('erroremail').style.display='block';   
      setTimeout(()=>{
         document.getElementById('erroremail').style.display='none';           
      },4000);
      valid=false;   
      return false;   
    }
    if(document.getElementById('email').value!=null){
      let emailCheck = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if(!emailCheck.test(document.getElementById('email').value)) {  
        document.getElementById('erroremail').style.display='block';             
        document.getElementById('erroremail').innerHTML='Please enter your valid email address';  
        setTimeout(()=>{
          document.getElementById('erroremail').style.display='none'; 
        },4000);
         
          valid=false;
          return false; 
      }
  }
    if(getmob=='' || getlenmob<=8){ 
      document.getElementById('errormob').style.display='block';  
      setTimeout(()=>{
        document.getElementById('errormob').style.display='none';           
     },4000);
      valid=false;
      return false;    
    }
    if(valid==true){
    //  document.getElementById('msgnote').style.display='block';     
      
      let formData = new FormData();  
      formData.append('action','unsubscribe');
      formData.append('subscribename',getname);      
      formData.append('subscribeemail',getemail);
      formData.append('subscribemobile',getmob);
     
      return fetch(api_url+"ajax_login.php",{
        method:'POST',
        headers: {
          'Accept':'application/json',
        },
        action:'unsubscribe',
        body: formData
        }).then((response)=> response.text()).then((resData)=> {     
              //  var resData='Failed';
  
          if(resData=='ok'){ 
            document.getElementById('msgnote').style.display='block'; 
            document.getElementById('msgnote').style.color='green';    
             document.getElementById('msgnote').innerHTML='Unsubscription request raised.';      
             
          }
          else{
            document.getElementById('msgnote').style.display='block';     
            document.getElementById('msgnote').style.color='red';     
            document.getElementById('msgnote').innerHTML='Something went wrong.';      
          }
        
      })
    }
     
         
  }
  return (
    <section className="row_hb">
      <div className="row_hb">
        <div className="container">
          <div className="row_hb ">
              <span className="lefttagicon"></span>
              <div className="row_hb pad_b_35 mob_p_b_0 pos_relative index_2 text_align_c">
              <div class="aboutAnimate moveimgBgSm loginMove index_1 pos_absolute"></div>
                <div className="Unsubscribe col-xs-12 col-sm-5 col-md-5 col-lg-5">
                  <h2 className="row_hb txt_color_5 fnt_weight_700 mar_a_0 pad_a_0 mob_text_align_c fnt_size_40 mon_font_size_25">              
                    <span className="txt_color_3">Unsubscribe</span>
                  </h2>
                  <span className='row_hb mar_b_15'>Request, removing your account & associated data.</span>
                  <div className='row_hb mar_t_10'>
                    <form action="" className='row_hb' onSubmit={formSubmit}>
                      <div className='row_hb mar_b_30 pos_relative '>
                        <input type="text" id="fname" className="form-control " placeholder='Name' name="username"  />
                        <span id='errorname' style={{'position':'absolute','display':'none','right':'0','color':'red','cursor':'pointer','font-size': '12px'}}>Please Enter Name</span>
                      </div>   
                      <div className='row_hb mar_b_30 pos_relative '>                  
                        <input type="text" id="email" className="form-control "  placeholder='Email'  name="email"  />
                        <span id='erroremail' style={{'position':'absolute','display':'none','right':'0','color':'red','cursor':'pointer','font-size': '12px'}}>Please Enter Email</span>
                      </div>   
                      <div className='row_hb mar_b_30 pos_relative '>               
                        <input type="text" id="mobile" className="form-control "  placeholder='Mobile No.'  name="mobile"  />
                        <span id='errormob' style={{'position':'absolute','display':'none','right':'0','color':'red','cursor':'pointer','font-size': '12px'}}>Please Enter Mobile Number</span>
                      </div>   
                      <button className="btn fnt_size_13 bg_light_blue mar_l_15 txt_color_4 btn_hov pad_a_8">Unsubscribe</button>
                      <span id="msgnote" className='row_hb mar_t_10 fnt_size_20' style={{'position':'absolute','display':'none','right':'0','color':'green','cursor':'pointer','font-size': '12px'}}>You have been successully unsubscribe.</span>
                    </form>

                  </div>
                </div>
                
              </div>
          </div>   
        </div>
      </div>
    </section>
  );
}

export default Unsubscribe;