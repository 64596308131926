import React,{ useState, useEffect, Fragment} from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";

import Header from './components/header_component/Header';
//import Otp from './components/otp_component/Otp';
import Home from './components/home_component/Home';
import Register from './components/register_component/Register';
import Thankyou from './components/thankyou_component/Thankyou';
//import Ss from './components/thankyou_component/Ss';
import About from './components/about_component/About';
import Benefit from './components/benefit_component/Benefit';
import Agent from './components/agent_component/Agent';
import Premiumpartner from './components/partner_component/Premium_partner';
import Supplier from './components/supplier_component/Supplier';
import Login from './components/login_component/Login';
import Signup from './components/signup_component/Signup';
import Blogs from './components/blogs_component/Blogs';
import Blog1 from './components/blogs_component/Blog1';
import Blog2 from './components/blogs_component/Blog2';
import Blog3 from './components/blogs_component/Blog3';
import Blog4 from './components/blogs_component/Blog4';
import Blog5 from './components/blogs_component/Blog5';
import Blog6 from './components/blogs_component/Blog6';
import Blog7 from './components/blogs_component/Blog7';
import Terms from './components/terms_component/Terms';
import Unsubscribe from './components/unsubscribe_component/Unsubscribe';
import Privacy from './components/privacy_component/Privacy';
import Infringement from './components/infringement_component/Infringement';
import Footer from './components/footer_component/Footer';
import Notfound from './components/notfound_component/Notfound';
import Plans from './components/thankyou_component/Plans';
//
function App() {

  const [authCheck,setAuthCheck] = useState(sessionStorage.getItem('resOTP'));

  useEffect(()=>{
    //  console.log("hi");
    //.   !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?    
    // n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    
    // if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    
    // n.queue=[];t=b.createElement(e);t.async=!0;
    
    // t.src=v;s=b.getElementsByTagName(e)[0];
    
    // s.parentNode.insertBefore(t,s)}
    // (window,document,'script','https://connect.facebook.net/en_US/fbevents.js');
    // fbq('init', '1088449961772747');     
    // fbq('track', 'PageView');
   
  })
 
  const googlepixel=()=>{
    console.log("hello");
  }

  return (
   
    <div className="travsie">
      <Router>
        <Fragment>
          <ScrollToTop />
            <div className="row_hb">
              <Header/>
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/index.html" component={Home}/>
                <Route path="/home" component={Home}/>
                <Route path="/register" component={Register}/>
                <Route path="/thankyou" component={Thankyou}/>
                <Route path="/about" component={About}/>
                <Route path="/premium_partner" component={Premiumpartner}/>
                <Route path="/benefit" component={Benefit}/>
                <Route path="/agent" component={Agent}/>
                <Route path="/supplier" component={Supplier}/>
                <Route path="/login" component={Login}/>
                <Route path="/signup/:type" component={Signup}/>
                <Route path="/terms" component={Terms}/>
                <Route path="/privacy" component={Privacy}/>
                <Route path="/unsubscribe" component={Unsubscribe}/>
                <Route path="/infringement" component={Infringement}/>
                <Route path="/blogs" component={Blogs}/>
                <Route path="/blog1" component={Blog1}/>
                <Route path="/blog2" component={Blog2}/>
                <Route path="/blog3" component={Blog3}/>
                <Route path="/blog4" component={Blog4}/>
                <Route path="/blog5" component={Blog5}/>
                <Route path="/blog6" component={Blog6}/>
                <Route path="/blog7" component={Blog7}/>
                <Route path="/plans" component={Plans}/>
              
                <Route component={Notfound}/>
              </Switch>
              <Footer/>
            </div>
        </Fragment>
      </Router>
    </div>
  );
}

export default App;