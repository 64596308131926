import React,{ useState, useEffect, Fragment} from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player'

import './Popup.css';

function Popup(prop) {
  useEffect(()=>{
    
  },[]);

  const hidePopup = ()=>{
    prop.setOnSuccess({flag:false,message:'',action:''});
  }

  return (
    <div className="row_hb popupCntr pos_fixed">
      <div className="container ht100">
        <div className="row_hb mid_block ht100">
            {prop.data.action==='videoLink'?<div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                <div className="row_hb popupBox pos_relative">
                    <i onClick={hidePopup} className="fa fclose fa-times pos_absolute" aria-hidden="true"></i>
                    <div className="row_hb text_align_c destTop_height_450">
                        <ReactPlayer playing="false" className='react-player' width="100%" height="100%" url={prop.data.message} />
                    </div>
                </div>
            </div>:null}
        
            {prop.data.action==='afterRegister'?<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div className="row_hb popupBox pos_relative">
                    <i onClick={hidePopup} className="fa fclose fa-times pos_absolute" aria-hidden="true"></i>
                    
                    <div className="row_hb text_align_c">
                        <h3 className="row_hb fnt_size_15 line_height_24">{prop.data.message}</h3>
                    </div>
                </div>
            </div>:null}

            {prop.data.action==='afterForgot'?<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <div className="row_hb popupBox pos_relative">
                    <i onClick={hidePopup} className="fa fclose fa-times pos_absolute" aria-hidden="true"></i>
                    
                    <div className="row_hb text_align_c">
                        {prop.data.message!=null?<h3 className="row_hb fnt_size_15 line_height_24">{prop.data.message}</h3>:null}
                        {prop.data.error!=null?<h3 className="row_hb fnt_size_15 line_height_24">{prop.data.error}</h3>:null}
                    </div>
                </div>
            </div>:null}

        </div>
      </div>
    </div>
  );
}

export default Popup;