import React,{ useEffect} from 'react';
import { useHistory, Link } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog7() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Travsie, a B2B Platform';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">

      <div className="row_hb blogsCntr">
        <img src={`${window.location.origin}/images/blog_7.jpg`} alt="Travsie, a B2B Platform"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">

                    <h2 className="font_fam_montserrat">Travsie, a B2B Platform <span>10 Sep 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>In my previous 6 blogs, I have tried to portray the challenges faced by holiday industry and how industry ignored the root cause of these challenges. We too ignored the challenges until Covid came. Then we realized that travel in “new normal” will demand digitization of back bone of the industry.</p>
                    <p>Let me introduce ‘Travsie.’ A platform that addresses all the challenges we raised in our previous blogs </p>
                    <p>Travsie is a seamless B2B platform that connects suppliers and agents on a common growth enablement platform. Travsie not only resolves the core industry challenges of customization, price and product parity, transparency, making B2B holiday booking process real time, and service issues – it also delivers equal opportunity growth for its ecosystem users, through its full stack suite of ease of business, and revenue generation features.</p>
                    <h4 className="font_fam_montserrat">"Travsie is a labour of love, built by an experienced, determined team that believes in understanding and then delivering with simplicity."</h4>
                    <p>With Travsie our vision is to evolve a thriving holidays platform that allows direct, transparent, efficient, convenient, and personalized buying between suppliers and travel agents. The platform is geared to generate demand and deliver real-time supply for its users. As we introduce newer transaction types, Travsie will expand market participation that will help smaller and medium size travel agencies grow and profit, while creating opportunities for bigger established companies as well.</p>
                    <p>Travsie is a labour of love, built by an experienced, determined team that believes in understanding and then delivering with simplicity. </p>
                    <p>Travsie is now LIVE! </p>
                    <p>For more information log onto <Link to="/"><strong>www.travsie.com</strong></Link> </p>
                
                </div>
                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog7;