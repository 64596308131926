import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Privacy.css';

function Privacy() {
  const [scrolled,setScrolled] = useState(false);
  const [getWidth,setGetWidth] = useState('forDesktop');
  const [getAdd,setGetAdd] = useState('');

  useEffect(() => {

    if(window.innerWidth<768){
      setGetWidth('forMobile');
    }else{
      setGetWidth('forDesktop');
    }

    getPagetitle();
    window.addEventListener('scroll',handleScroll);
    return(()=>{
      window.removeEventListener('scroll',handleScroll);
    })
  });

  const getPagetitle = ()=>{
    document.title = 'Privacy Policy';
  }

  const handleScroll = ()=>{
    window.scrollY >50?setScrolled(true):setScrolled(false);
    if(window.scrollY>4950){
      setScrolled(false);
    }

    if(window.innerWidth<768){
      setScrolled(true);
    }
  }

  const scrolltoElm = (activeId,idd)=>{
    let scrollDiv = document.querySelector("#"+idd).offsetTop-95;
    if(document.querySelector('.parentUlElm a.active') != null){
      document.querySelector('.parentUlElm a.active').classList.remove('active');
    }
    document.querySelector("#"+activeId).classList.add('active');
    window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
    getActivate();
  }

  const getActivate = ()=>{
    getAdd==''?setGetAdd('active'):setGetAdd('');
  }

  return (
    <section className="row_hb">
      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    <h2 className="privacyTitle">Privacy <span className="txt_color_3">Policy</span></h2>
                    <p>We value the trust you place in us and recognize the importance of secured transactions and information privacy. </p>
                    <p>This privacy policy (“the Policy”) will help you understand what information we collect, why we collect it, how we use it, including if and how we share the information with third parties, and the choices you have in relation to your information.</p>
                    <p>This Privacy Policy describes how Travsie Platform of Travshop Private Limited and its affiliates (collectively “Travshop/we/us/our") collect, collate, use, share or otherwise process your personal information through Travshop website / platform www.travsie.com or its mobile application(s).</p>
                    <p>By visiting the “Travsie” Platform, providing /furnishing your information or availing our product/service, you expressly accord consent to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions. If you do not agree, please do not access or use our Platform. Travshop is committed to maintain utmost confidentiality, integrity and security of all information of our Users subject to the Policy.  It is important to read and understand the entire Privacy Policy along with our Terms of Use carefully as some of the provisions affect your legal rights and obligations. It is strongly recommended for you to return to this page periodically to review the most current version of the Policy.</p>

                    <h3 id="collection">1. Collection of Your Information</h3>

                    <p>When you use our Platform, we collect and store your information which is provided by you from time to time. In general, you can browse the Platform without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. Wherever possible, we indicate which fields are mandatory and which fields are optional. You always have the option to not provide information by choosing not to use a particular service, product or feature on the Platform.</p>

                    <p>We may track your buying behaviour, preferences, and other information that you choose to provide on our Platform. We use this information to do internal research on our users' demographics, interests, and behaviour to better understand, protect and serve our users. This information is compiled and analysed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on our Platform or not), which URL you next go to (whether this URL is on our Platform or not), your computer browser information, and your IP address.</p>

                    <p>We collect personal information (such as email address, office address, name, phone number etc.) from you when you set up an account or transact with us. While you can browse some sections of our Platform without being a registered member, certain activities (such as placing an order or consuming our online content or services) do require registration. We do use your contact information to send you offers based on your previous orders and your interests. We do not store details of your credit card/debit card and other payment instrument/options exercised by you at our Platform. </p>

                    <p>If you choose to post messages on our message boards, chat rooms or other message areas or leave feedback, we will collect such information and may retain and use such information in future to improve our services / products, resolve disputes, provide customer support and address  problems as is permitted by applicable law.</p>

                    <p>If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website / Platform, we may collect such information into a file specific to you for future reference, use and record.</p>

                    <p>Travsie Platform has on board certain third-party business partners /associates/ suppliers/parties on the Platform who specialise in the categories like travel ticket reservations, visa issuance, online hotel booking or sightseeing arrangements). If you use the services of these online suppliers/partners/third parties, you accord your specific and conscious consent for automatic sharing of your information with such outside parties. Travshop shall not be responsible and cannot be held liable for these suppliers/third parties’ privacy practices / policies or the content of their privacy policies, and we request you to read their privacy policies prior to disclosing any information or using services of these suppliers through Travshop platform or otherwise.</p>

                    <h3 id="profile">2. Use of Demographic / Profile Data / Your Information</h3>

                    <p>We use your personal information to provide the product and services you request through suppliers/third parties. We use your personal information to help suppliers and business partners /associates in handling and fulfilling your request and enhance your customer experience; resolve disputes; address /troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services; inform you about online and offline offers, products, services, and updates from time to time; customize and enhance your experience; detect and protect us against errors, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection of information.</p>

                    <p>With your consent, we may also request you to provide your Know Your Client details like PAN, GST Number, recognised ID cards / number and KYC details to: (i) check your eligibility to use Travsie Platform services; (ii) issue GST invoice for the products and services you have purchased for your business requirements; (iii) enhance your experience on the Platform and provide you access to the products and services being offered by us, other sellers, affiliates/partners/suppliers. You understand that your access to these products/services may be denied in case your specific consent is not provided to us. </p>

                    <p>In our efforts to continually improve our product and service offerings, we and our affiliates collect and analyse demographic and profile data about our users' activity on our Platform. We identify and use your IP address to help diagnose problems with our server, and to administer our Platform. Your IP address is also used to help identify you and to gather broad demographic information.</p>

                    <p>We may occasionally request you to participate in optional surveys conducted either by us or through a third party market research agency. These surveys may ask you for personal information, contact information, date of birth, demographic information (like zip code, age, or income level, etc.), attributes such as your interests, household or lifestyle information, your purchasing behaviour or history, preferences, and such other information that you may choose to provide. The surveys may involve collection of voice data or video recordings, the participation of which would purely be voluntary in nature. We will use this data to meet your expectations at our Platform, providing you with content that we think you might be interested in and to display content according to your preferences and needs.</p>

                    <h3 id="cookies">3. Cookies</h3>

                    <p>We use data collection devices such as "cookies" on certain pages of the Travsie Website / Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal information. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. We use cookies from third-party partners such as Google Analytics (can we use such third party contents and claim so) for marketing and analytical purposes. Google Analytics help us understand how our customers use the site. You can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.</p>

                    <h3 id="personalInfo">4. Sharing of personal information</h3>

                    <p>We may share personal information with our partners and affiliates for providing products and services offered by them. These entities and affiliates may further share such information with their affiliates, business partners and other third parties for the purpose of providing their products and services, and may market to you as a result of such sharing unless you explicitly opt-out for such sharing of information.</p>

                    <p>We may disclose your personal information to third parties, such as suppliers, business partners. This disclosure may be required for us to provide you access to our products and services; for fulfilment of your orders; for enhancing your experience; for providing feedback on products; to collect payments from you; to comply with our legal obligations; to conduct market research or surveys; to enforce our Terms of Use; to facilitate our marketing and advertising activities; to analyse data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our product and services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</p>

                    <p>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>

                    <p>We and our affiliates will share (with or without consideration) some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur, we will ensure that other business entity (or the new combined entity) will be required to strictly follow this Privacy Policy with respect to your personal information.</p>

                    <h3 id="linksTo">5. Links to Other Sites</h3>

                    <p>Our Platform may provide links to other websites or applications that may collect personal information about you. We are not responsible and liable for the privacy practices or the contents of those linked websites.</p>
                    <h3 id="security">6. Security Precautions</h3>

                    <p>We take utmost steps to provide reasonable physical, electronic and procedural safeguards and safety to protect your information at all times. Whenever you access your account information, we offer the use of a secured server/digital platform. Once your information is in our domain we try our best to adhere to our security guidelines & protocols  to protect against unauthorized access and piracy.  However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secured, and therefore, there would always remain certain inherent risks regarding use of the Platform/server.   The Users are themselves responsible for ensuring the protection of their login ids and password records for their respective accounts on the Platform.</p>

                    <h3 id="choice">7. Choice/Opt-Out</h3>
                    <p>We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications after setting up an account with us. If you do not wish to receive promotional communications /  mails from Travshop  then you can always opt out / unsubscribe of those promotional mails by using the link at any time.</p>

                    <h3 id="advertisements">8. Advertisements on Platform</h3>

                    <p>We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (without limitation your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.</p>

                    <h3 id="children">9. Children Information</h3>
                    <p>We do not knowingly solicit or collect personal information from children under the age of 18 and use of our Platform is available only to legally competent persons who can form a legally binding contract under the Indian Contract Act, 1872. Travsie.com being a B2B platform meant for commercial purposes only, hence we do not encourage use of this website by children and is prohibited. Travshop does not own any liability and responsibility for any such misuse by a person below 18 year of age. Travsie does not encourage any person below 18 years to use this platform or visit www.travsie.com </p>

                    <h3 id="data">10. Data Retention</h3>
                    <p>We retain your personal information in accordance with applicable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you for an extended period if we believe that it may be necessary to prevent fraud or future abuse or if required by law or for other legitimate purposes. We may continue to retain your data in anonymised form for analytical and research purposes.</p>

                    <h3 id="consent">11. Your Consent</h3>
                    <p>By visiting our Platform or by providing your information, you expressly accord your consent to the collection, use, storage, disclosure and otherwise processing of your information (including exclusive personal information) on the Platform in concurrence with this Privacy Policy. If you disclose to us any personal information relating to other people, you ensure and represent that you have the requisite authority to do so and permit us to use such information in concurrence with this Privacy Policy.</p>

                    <p>You, while providing your personal information over the Platform or any partner platforms or establishments, consent to us (including to our affiliates, marketing channels, business partners, associates and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.</p>

                    <h3 id="changes">12. Changes to this Privacy Policy</h3>
                    <p>Travshop may make changes to this Privacy Policy from time to time to meet the needs of the Platform, we urge you to continuously keep checking our Privacy Policy  for such changes. We may update this Privacy Policy to reflect changes to our information practices. We may alert you to significant changes by posting the date our policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.</p>

                    <h3 id="grievance">13. Grievance Officer</h3>
                    <p>If you have any concerns or questions in relation to this Privacy Policy, you may address them to our Grievance Officer In accordance with Information Technology Act 2000 and Rules made there under, whose name and contact details are as follows:</p>
                    <p>Mr. Jay Gurnani<br/>Tarvshop Pvt Ltd.<br/>315 / 274, 2nd Floor Westend Marg <br/>New dlehi 110030<br/>Phone: +91 - 9718739494 <br/>Email: <a href="mailto:grievance.officer@travsie.com"><strong> grievance.officer@travsie.com</strong></a><br/>Time: Mon - Sat (9:00 - 18:00)</p>

                </div>

                <div className="blogLeft privacy pos_relative">
                  <div className={scrolled==true?"row_hb fixboxElm active pos_fixed":"row_hb fixboxElm"}>
                    
                    {getWidth=='forMobile'?<i onClick={getActivate} className={getAdd==''?"fa mIcon fa-arrow-circle-left":"fa mIcon fa-arrow-circle-right"} aria-hidden="true"></i>:null}

                    <div className={`row_hd  ${getWidth} ${getAdd}`}>
                      <h5 className="font_fam_roboto mar_b_0">Read More</h5>
                      <ul className="row_hb font_fam_roboto parentUlElm">
                        <li><a id="tab1" onClick={()=>scrolltoElm('tab1','collection')}>Collection of Information</a></li>
                        <li><a id="tab2" onClick={()=>scrolltoElm('tab2','profile')}>Profile Data / Information</a></li>
                        <li><a id="tab3" onClick={()=>scrolltoElm('tab3','cookies')}>Cookies</a></li>
                        <li><a id="tab4" onClick={()=>scrolltoElm('tab4','personalInfo')}>Sharing of personal info.</a></li>
                        <li><a id="tab5" onClick={()=>scrolltoElm('tab5','linksTo')}>Links to Other Sites</a></li>
                        <li><a id="tab6" onClick={()=>scrolltoElm('tab6','security')}>Security Precautions</a></li>
                        <li><a id="tab7" onClick={()=>scrolltoElm('tab7','choice')}>Choice/Opt-Out</a></li>
                        <li><a id="tab8" onClick={()=>scrolltoElm('tab8','advertisements')}>Advertisements on Platform</a></li>
                        <li><a id="tab9" onClick={()=>scrolltoElm('tab9','children')}>Children Information</a></li>
                        <li><a id="tab10" onClick={()=>scrolltoElm('tab10','data')}>Data Retention</a></li>
                        <li><a id="tab11" onClick={()=>scrolltoElm('tab11','consent')}>Your Consent</a></li>
                        <li><a id="tab12" onClick={()=>scrolltoElm('tab12','changes')}>Changes to this Privacy Policy</a></li>
                        <li><a id="tab13" onClick={()=>scrolltoElm('tab13','grievance')}>Grievance Officer</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </div>   
        </div>
      </div>
    </section>
  );
}

export default Privacy;