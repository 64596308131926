import React,{ useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog1() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Unpopular Opinion';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">
      <div className="row_hb blogsCntr">
          <img src={`${window.location.origin}/images/blog_1.jpg`} alt="UNPOPULAR OPINION: Pandemic’s Travel Pause was a Good Thing"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    <h2 className="font_fam_montserrat">UNPOPULAR OPINION: Pandemic’s Travel Pause was a Good Thing <span>18 August 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>I will admit the headline seems controversial – but bear with me on this one. Yes, the Covid 19 pandemic did bring unwelcome rest to the travel industry. To make matters worse, the devastating 2021 second wave shattered sentiments, and subdued short-term demand. This double whammy has taken a hard toll on the industry – on airlines, hotels, business partners, agents, and suppliers – many of whom I call friends. So how can this be good?  </p>
                    <p>In one word, evolution. As Dr Ian Malcom’s character said in the ‘Jurassic Park’ “Life always finds a way.” And the travel industry did, and most certainly will – for the better.</p>
                    <p>Last year, the airlines gave a perfect example of the resilience our industry can show. After a complete 2 month pause – the airlines pivoted, got their finances in order, started new business channels and managed demand and fleets to get back to nearly 70% of 2019 traffic levels by February 2021. Look at a country like Dubai. They went laser sharp and future-focused in their approach and changed the dynamics of “pandemic tourism”. Relaxed policies and opening new activities in a calibrated manner helped them attract 1.79 million tourists in 2020, versus 1.69 million tourists in 2019.</p>
                    <h4 className="font_fam_montserrat">“However, I know when we restart, we will do something new and fresh in terms of marketing, technology and products” </h4>
                    <p>That is what got us thinking – and led to this controversial title of this first blog in a series that will examine the current and future state of travel – especially the holiday business. </p>
                    <p>But before confirming our suspicions, we also wanted to understand the micro picture – does our opinion align with the voice on the street? So, we spoke to Mr. Rajesh Changalani from Mumbai about the pandemic’s impact on his business. “Of course, the market crashed, business slowed down, and my cash flows were low. Paying rent, overheads, and salaries when income and customers are less, is difficult. So, I spent my “pandemic pause” researching and well…resting.” </p>
                    <p>“However, I know when we restart, we will do something new and fresh in terms of marketing, technology and products for our customers. There’s so much pent-up international demand for destinations like Dubai, Singapore and Russia – I will figure out how to capitalize on that.” he added.</p>
                    <p>Rajesh’s latter statement captures in essence what we want to say. In short, the travel industry always needed a reset – and evolution. It needed to get out of the rut it has been in, to create something new, and emerge as phoenix from the ashes. Sadly, it took a pandemic to get us to that realization. </p>
                    <p>The post-COVID world will come with a new set of rules – and we need to prepare for the changing dynamics with urgency. Be it airlines, countries, hotels and others – there is a pressing need to use new technology and business innovations to thrive in the new normal. The pandemic’s pause has given us the opportunity to do just that. </p>
                    <p>As we proceed onto the next few blogs – we will look at the challenges, and more importantly, the solutions. We are building up to something with the Travsie blog series. </p>
                    <p>Stay tuned. </p>
                </div>

                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog1;