import React,{ useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog6() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Holy Grail';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">

      <div className="row_hb blogsCntr">
      <img src={`${window.location.origin}/images/blog_6.jpg`} alt="What is the Holy grail of the holiday industry?"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    
                    <h2 className="font_fam_montserrat">What is the Holy grail of the holiday industry? <span>07 Sep 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>In our previous blogs, we have focused on varied challenges that plague the holiday packages industry – especially in the B2B segment. From the pitfalls of manual customization and dependency on middlemen, to the counterproductive nature of price and product variation and other operational and service challenges. </p>
                    <p>If the holiday industry is to evolve and thrive in the new world, then the players need to introspect. It is one thing to find problems, and another to get to the root of them to deliver solutions. Solutions when superficial, are tricky. We have seen this before with some start-ups and business who tried to deliver a ‘solution’ to problem statement, without truly delving into the root of the problem.</p>
                    <p>If one were to take a wide perspective, then the root of all these challenges boil down to manual processes and an unorganized inventory at sourcing side of the holiday business.</p>
                    <p>So, let’s get a few things straight: </p>

                    <h3 style={{marginBottom:'10px'}}>Customising for sake of customization doesn’t work  </h3>
                    <p>We have seen start-ups in the past 5 years enter the B2C segment with the Holy Grail of “Customization”. It is somewhat commendable that they tried to understand the customer’s intent and tried to deliver UI/UX led solutions. But, the true root of holiday package customization lies not in understanding the customer but understanding the “sourcing”.  Sure, the customer gets to ‘play around’ on your site, but in a world where suppliers and agents still work manually via phones or emails, is the pricing, or even the availability in real-time? No? In that case, it truly isn’t a solution. That is like solving a dermatological problem with make-up. </p>

                    <h3 style={{marginBottom:'10px'}}>A Marketplace approach then? Would that work?  </h3>
                    <p>A traditional marketplace format helps product affordability, accessibility, and convenience for its eco system members. This has not always held true for the tourism industry. Some factors that inhibit a marketplace’s effectiveness for tourism include integration of non-commoditized products, and intangible services that a supplier provides to agents. Any marketplace for travel/holidays needs to be more than just a platform to connect buyers and sellers – it needs to be a disruptive platform that creates new business opportunities for all. </p>

                    <h3 style={{marginBottom:'10px'}}>B2B is where it is at, and it where it has always been.   </h3>
                    <p>Like we mentioned earlier, the pandemic threw tons of challenges for industry players – and this is across industries. In travel we saw challenges pertaining to operations, service, and refunds – therefore, most B2B buyers now want more transparency, choice, convenience & personalization. Studies suggest that 80% B2B buyers across industries expect a convenient buying experience like those offered by B2C website. We are seeing this trend across recent B2B platforms – be it Udaan, a trade portal for small and medium businesses in India, or Ninjacart, a farm-to-retail platform or SOLVE, a full stack B2B marketplace backed by SCB. A strong B2B network is the key to securing the future of the industry.</p>

                    <p>Then, has anyone cracked the concept, yet?<br/> You would think someone would by now, right? But nope! No start-up has tried to create a B2B platform to address the core challenges of holiday industry.</p>
                    <p>I’ll conclude by keeping it real. Unless the holiday industry gets on to a B2B transacting platform with real time connectivity between its holiday sellers – the challenges in holiday space will never end. That is what we have been building up to with these blogs. We want to end those challenges, we want people to grow, we want to change the way agents and suppliers interact – and are in the right place to do it. </p>
                    <p>Why? Well, because we understand the system, we have a seasoned team that knows the system’s ins-and-outs. We know what it will take because we have been there – and we know how to deliver what the industry needs. Stay tuned, for what’s coming next.</p>
                
                </div>

                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog6;