import React,{ useState, useEffect,useRef} from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import Popup from '../popup_component/Popup';
import holiday_bg from '../../images/holiday_bg.gif';

import './Login.css';

function Login() {
  const [notRegUser,setNotRegUser] = useState(false);
  const [getType, setGetType] = useState('agent');
  const [onSuccess,setOnSuccess] = useState({flag:false,message:'',action:''});

  const [forgotPassword,setForgotPassword] = useState(true);
  const [typeLogin,setTypeLogin] = useState(false);
  const [typebyLogin,setTypebyLogin] = useState(true);
  const [timeLogin,setTimeLogin] = useState(false);
  const [tagtype,setTagtype] = useState('');

  const [emailMsgAlert,setEmailMsgAlert] = useState(false); 
  const [mobileMsgAlert,setMobileMsgAlert] = useState(false); 
  const [otpMsgAlert,setOtpMsgAlert] = useState(false); 
  const [passwordMsgAlert,setPasswordMsgAlert] = useState(false); 
  
  const [actCls,setActCls] = useState(null); 
  const [activeCls,setActiveCls] = useState(''); 

  const [emailMsg,setEmailMsg] = useState('Please enter your email address.');
  const [mobileMsg,setMobileMsg] = useState('Please enter your mobile address.');
  const [otpMsg,setOtpMsg] = useState('Please enter your otp number.');
  const [passwordMsg,setPasswordMsg] = useState('Please enter your password.');

  const emailRef = useRef(null);
  const mobileRef = useRef(null);
  const passwordRef = useRef(null); 
  const mobotpRef = useRef(null); 
  const api_url = 'https://www.travsie.com/admin/';
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  //const api_url = 'http://192.168.0.5/www.travsie.com/admin/';


 useEffect(()=>{  
    getPagetitle();  

    document.addEventListener("keyup", keypressAct);
    document.addEventListener("mousedown", handleClick);
   
    return(()=>{
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keyup", keypressAct);
     
    })    
  },[forgotPassword]);


  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
    
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);


  
// if(api_url !==""){
//   let formDataone = new FormData();  
//   formDataone.append('action','logincheck');    
//  fetch(api_url+"ajax_login.php",{
//     method:'POST',
//     headers: {
//       'Accept':'application/json',
//     },
//     body: formDataone
//     }).then((response)=> response.json()).then((resData)=> {         
//       if(resData.login==="Y"){
//         alert(resData.msg);
//       }
//   })
// }

  const forgotPass = ()=>{
    setNotRegUser(false);
    setForgotPassword(!forgotPassword);
  }

  const getPagetitle = ()=>{
    document.title = 'Login';
  }

  const handleClick = e => {

    if(document.getElementById('email').value!=''){
      if (emailRef.current && !emailRef.current.contains(e.target)){
        setActiveCls('active');
      }
    }else{
      if (emailRef.current && !emailRef.current.contains(e.target)){
        setActiveCls(null);
      }
    }

   if(document.querySelector('.tagtype').value =="mobtag" ){
    if(document.getElementById('mobile').value!=''){
      if (mobileRef.current && !mobileRef.current.contains(e.target)){
       // setActiveCls('active');      
        document.getElementById("mobrefhead").classList.add("active");
      }
    }
    else{
      if (mobileRef.current && !mobileRef.current.contains(e.target)){
       // setActiveCls(null);
        document.getElementById("mobrefhead").classList.remove("active");
      }
    } 

    if(document.getElementById('getotp').value!=''){
      if (mobotpRef.current && !mobotpRef.current.contains(e.target)){
       // setActiveCls('active');      
        document.getElementById("mobotphead").classList.add("active");
      }
    }
    else{
      if (mobotpRef.current && !mobotpRef.current.contains(e.target)){
       // setActiveCls(null);
        document.getElementById("mobotphead").classList.remove("active");
      }
    } 

    
  
  }



    if(forgotPassword==true){
      if(document.getElementById('password').value!=''){
        if(passwordRef.current && !passwordRef.current.contains(e.target)){
          setActCls('active');
        }
      }else{
        if(passwordRef.current && !passwordRef.current.contains(e.target)){
          setActCls(null);
        }
      }
    }
    
  }

  const keypressAct = e => {
    if(e.keyCode===9){
      if(e.target.id=='email'){ setActiveCls('active'); }     
      if(e.target.id=='password'){  setActCls('active'); }
      if(getType=="supp" && tagtype=="mobtag" ){
        if(e.target.id=='mobile'){  setActCls('active'); }    
      }     
    }
  }



const formSubmit = (event)=>{
  event.preventDefault();
  let valid = true;
  if(getType=='agent'){
    if(document.getElementById('email').value==''){
      setEmailMsgAlert(true); 
      valid = false;
      setEmailMsg('Please enter your email address.'); 
      setTimeout(()=>{
        setEmailMsgAlert(false);
      },4000);
    }
    if(document.getElementById('email').value!=null){
        let emailCheck = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!emailCheck.test(document.getElementById('email').value)) {
          setEmailMsgAlert(true);
          valid = false;
          setEmailMsg('Please enter your valid email address.'); 
            setTimeout(()=>{
              setEmailMsgAlert(false);
            },4000);
        }
    }
    if(forgotPassword==true){
      if(document.getElementById('password').value==''){
          setPasswordMsgAlert(true);
          valid = false;
          setPasswordMsg('Please enter your password.');
          setTimeout(()=>{
            setPasswordMsgAlert(false);
          },4000);
      }
    }
    if(valid===true && forgotPassword==true){
      if (document.querySelector('#travsieFrm') !== '') {        
        document.querySelector('#travsieFrm').submit();
      }
    }   
  }
  if(getType=='supp'){
      if(document.querySelector('.tagtype').value==='mobtag'){   
          var getmobnum=document.getElementById('mobile').value;
          var getotp=document.getElementById('getotp').value;  
        if(getmobnum===''){
          setMobileMsgAlert(true); 
          valid = false;
          setMobileMsg('Please enter your mobile number.'); 
          setTimeout(()=>{
            setMobileMsgAlert(false);
          },4000);
        }  
        if(getmobnum!==''){
          let mobCheck = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
          if(!mobCheck.test(document.getElementById('mobile').value)) {
            setMobileMsgAlert(true);
            valid = false;
            setMobileMsg('Please enter your valid mobile number.'); 
              setTimeout(()=>{
                setMobileMsgAlert(false);
              },4000);
          }
          else if(document.getElementById('mobile').value.length <=7 && document.getElementById('mobile').value.length > 10 ){
            setMobileMsgAlert(true);
            valid = false;
            setMobileMsg('Please enter your valid mobile number.'); 
              setTimeout(()=>{
                setMobileMsgAlert(false);
              },4000);
          }

        }
        if(getotp==='' && document.getElementById('mobile').value.length <=10 && document.getElementById('mobile').value.length >= 8){
          setOtpMsgAlert(true); 
          valid = false;
          document.getElementById('msgsend').innerHTML="";
          setOtpMsg('Please enter your Otp number.'); 
          
          setTimeout(()=>{
            setOtpMsgAlert(false);
          },4000);
        }
      
        if(getotp!==''){
          let otpData =  document.getElementById('getotp').value; 
          let mobData = document.getElementById('mobile').value;
          let formData = new FormData(); 
              
          formData.append('login_type', getType);
          formData.append('action','suppcheckotp');
          formData.append('otp',otpData);
          formData.append('mobile',mobData);
      
          fetch(api_url+"ajax_login.php",{
            method:'POST',
            headers: {
              'Accept':'application/json',
            },
            body: formData
            }).then((response)=> response.text()).then((resData)=> {
        
              if(resData!='ok'){           
                setOtpMsgAlert(true); 
                valid = false;
                document.getElementById('msgsend').innerHTML="";
                setOtpMsg('Please enter correct Otp.'); 
                setTimeout(()=>{
                  setOtpMsgAlert(false);
                },4000);
                
              }
              else{
                if(valid===true && forgotPassword==true){
                  if (document.querySelector('#travsieFrm') !== '') {
                    document.querySelector('#travsieFrm').submit();
                  }
                }
              }
          })
        }
      }  
      if(document.querySelector('.tagtype').value==='usertag') {
        if(document.getElementById('email').value==''){
          setEmailMsgAlert(true); 
          valid = false;
          setEmailMsg('Please enter your email address.'); 
          setTimeout(()=>{
            setEmailMsgAlert(false);
          },4000);
        }        
        if(document.getElementById('email').value!=null){
            let emailCheck = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if(!emailCheck.test(document.getElementById('email').value)) {
              setEmailMsgAlert(true);
              valid = false;
              setEmailMsg('Please enter your valid email address.'); 
                setTimeout(()=>{
                  setEmailMsgAlert(false);
                },4000);
            }
        }
        if(forgotPassword==true){
          if(document.getElementById('password').value==''){
              setPasswordMsgAlert(true);
              valid = false;
              setPasswordMsg('Please enter your password.');
              setTimeout(()=>{
                setPasswordMsgAlert(false);
              },4000);
          }
        }
        if(valid===true && forgotPassword==true){
          if (document.querySelector('#travsieFrm') !== '') {        
            document.querySelector('#travsieFrm').submit();
          }
        }
      }
  } 

  if(valid===true && forgotPassword==false){
    forgotPass();    
    let formData = new FormData();  
    formData.append('email',document.getElementById('email').value);
    formData.append('login_type', getType);
    formData.append('action','forgotPassword');
    return fetch(api_url+"ajax_login.php",{
      method:'POST',
      headers: {
        'Accept':'application/json',
      },
      body: formData
      }).then((response)=> response.json()).then((resData)=> {
        console.log('response',resData);
        if(resData.success!=null){
          console.log('response 1',resData);
          setOnSuccess({flag:!onSuccess.flag,message:resData.success,action:'afterForgot'})         
        }

        if(resData.error!=null){
          console.log('response 2',resData);
          setOnSuccess({flag:!onSuccess.flag,message:resData.error,action:'afterForgot'})       
        }
       
        document.getElementById('email').value = '';
        setActiveCls('active');     

    })
  }
}

const fixTopact = (type)=>{
  if(type="email"){
    type==='email'?setActiveCls('active'):setActCls('active');
  }
  
}
const sendOtp = (event)=>{ 
  var mobnum=document.getElementById('mobile').value;
  var getlenmob=mobnum.length;
  document.getElementById('msgsend').innerHTML="";
  setRunTimer(false);
  setCountDown(0);
  if(getlenmob>='8'){    
      let formData = new FormData();  
      formData.append('action','suppmob');
      formData.append('suppmobile',mobnum);
     
      return fetch(api_url+"ajax_login.php",{
        method:'POST',
        headers: {
          'Accept':'application/json',
        },
        action:'suppmob',
        body: formData
        }).then((response)=> response.text()).then((resData)=> { 
          console.log('timeLogin',timeLogin);
          if(resData=='ok'){           
            setTimeLogin(true);
            setRunTimer(true);
            document.getElementById('timesend').style.display='block';
            document.getElementById('msgsend').style.color='green';
            document.getElementById('notsend').innerHTML='';
            document.getElementById('msgsend').innerHTML="Please enter the code send to your mobile number";
            
          }
          else{
            //setTimeLogin(true);    
            document.getElementById('timesend').style.display='none';           
             document.getElementById('notsend').style.color='red';        
            document.getElementById('notsend').innerHTML=resData;
          }
          
          //resData=12345;
        
      })
  }
  else{
    document.getElementById('notsend').style.color='red';        
    document.getElementById('notsend').innerHTML="Please enter your mobile number";
    document.getElementById('timesend').style.display='none';

  }
}

// const reSendotp=(event)=>{
//   var mobnum=document.getElementById('mobile').value;  
//   let formData = new FormData();  
//   formData.append('action','suppmob');
//   formData.append('suppmobile',mobnum); 
//   setRunTimer(false);
//   setCountDown(0);
  
//   return fetch(api_url+"ajax_login.php",{
//     method:'POST',
//     headers: {
//       'Accept':'application/json',
//     },
//     action:'suppmob',
//     body: formData
//     }).then((response)=> response.text()).then((resData)=> {
//       resData='ok';  
//       if(resData=='ok'){           
//         setRunTimer(true);
//         setTimeLogin(true);
//         document.getElementById('msgsend').innerHTML="Please enter the code send to your mobile number";
        
//       }
//       else{
//         document.getElementById('notsend').style.color='red';        
//         document.getElementById('notsend').innerHTML=resData;
//       }
     
//   })

// }

  const checkType = (type,idd,url,pwdId)=>{
    setGetType(type);
    if (document.querySelector('#'+idd) != null) {
      document.querySelector('#'+idd).setAttribute("action",url);
    }

    if (document.querySelector('#password') != null) {
      document.querySelector('#password').setAttribute("name",pwdId);
    }
    if(type=='supp'){   
      document.getElementById('mobtag').style.background='#deecf8';     
      document.getElementById('usertag').style.background='';          
      document.getElementById('mobtag').style.color='#337ab7';     
      document.getElementById('usertag').style.color='#777';  
      setTagtype('mobtag'); 
      setTypeLogin(true);  
      setTypebyLogin(false);     
    }
    if(type=='agent'){  
      setTagtype('usertag');       
      setTypeLogin(false); 
      setTimeLogin(false);
      setRunTimer(false);  
      setTypebyLogin(true);   
      // document.getElementById('msgsend').innerHTML="";    
    }

  }

  const mAction = (idd)=>{

    if(document.querySelector('#'+idd) != null) { 

      if(idd=='email'){
        if(document.querySelector('#'+idd).value=='') {
          setActiveCls('active');        
          document.querySelector('#'+idd).focus();
          return false;
        }else{          
          setActiveCls(null);
        }
      }
      else if(idd=='mobile'){
       
        if(document.querySelector('#'+idd).value=='') {
         // setActiveCls('active');   
          document.getElementById("mobrefhead").classList.add("active");     
          document.querySelector('#'+idd).focus();
          return false;
        }else{  
          document.getElementById("mobrefhead").classList.remove("active");        
          //setActiveCls(null);
        }
      }
      else if(idd=='getotp'){
        if(document.querySelector('#'+idd).value=='') {
          document.getElementById("mobotphead").classList.add("active");
          //setActiveCls('active');        
          document.querySelector('#'+idd).focus();
          return false;
        }else{          
         // setActiveCls(null);
          document.getElementById("mobotphead").classList.remove("active");
        }
      }
      else{      
        if(document.querySelector('#'+idd).value=='') {
          setActCls('active');        
          document.querySelector('#'+idd).focus();
          return false;
        }else{       
          setActCls(null);
        }
      } 
    }
  }

  const getLogin =(tag)=>{
      if(tag=="mobile"){
        document.getElementById('mobtag').style.background='#deecf8';     
        document.getElementById('usertag').style.background='';     
        document.getElementById('mobtag').style.color='#337ab7';     
        document.getElementById('usertag').style.color='#777';  
        setTagtype('mobtag');
        setTypeLogin(true); 
      }
      else{
        document.getElementById('mobtag').style.color='#777';     
        document.getElementById('usertag').style.color='#337ab7';   
        document.getElementById('usertag').style.background='#deecf8';   
        document.getElementById('mobtag').style.background='';
        setTagtype('usertag');
        setTypeLogin(false); 
        setTimeLogin(false);
        setRunTimer(false);   
        // document.getElementById('msgsend').innerHTML=""; 
      }
  }
  const notreg = ()=>{
    setNotRegUser(!notRegUser);
  }
  

  return (
    <section className="row_hb loginCntr">
        {onSuccess.flag==true?<Popup setOnSuccess={setOnSuccess} data={onSuccess}/>:null}
        <div className="container">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_l_0 pad_r_80 mob_p_r_0 bg_col min_ht_600 mob_min_ht pos_relative">
            <div class="aboutAnimate moveimgBgSm loginMove index_1 pos_absolute"></div>
              <div className="row_hb loginBox border_rad_8 bg_white mar_t_80 mar_b_60 pos_relative index_2">
                {forgotPassword?<h2 className="row_hb txt_color_5 mobfnts35 fnt_weight_800 mar_a_0 pad_a_0 mon_font_size_25 mob_text_align_c fnt_size_50">Login to  <span className="txt_color_3">travs<span className="i_ui" >i</span>e</span></h2>:<h2 className="row_hb txt_color_5 mobfnts35 fnt_weight_800 mar_a_0 pad_a_0 mon_font_size_25 mob_text_align_c fnt_size_50" style={{'fontSize':'48px'}}>Forgot  <span className="txt_color_3">password</span></h2>}
                
                <div className="row_hb loginType pad_t_25">
                  <a onClick={()=>checkType('agent','travsieFrm','../admin/login.php','password')} id="agent" className={getType=='agent'?'active':''}>Agent Login</a>
                  <a onClick={()=>checkType('supp','travsieFrm','../supp_admin/login.php','pass')} className={getType=='supp'?'active':''} id="supplier">Supplier Login</a>
                </div>

                <form className="row_hb"  id="travsieFrm" style={{"position":"relative",'margin-top':'12px'}} method="post"   action={forgotPassword?'../admin/login.php':null} autocomplete="off" onSubmit={formSubmit}>
                 
                  {forgotPassword?<input type="hidden" name="action" value="pzIkqJImqS9fo2qcot=="/>:null}
                 
                 <div className={`row_hb ${typebyLogin ? "display_none" : ""}`} style={{'margin-bottom':'12px'}} >
                 
                  <span id='mobtag' className='display_none' onClick={()=>getLogin('mobile')} style={{'padding':'3px 16px','display':'inline-block','color':'#337ab7','font-weight':'500','cursor':'pointer','background': '#deecf8','font-size':'13px','border-radius':'4px'}}>Mobile No</span>
                  <span  id='usertag'  className='display_none' onClick={()=>getLogin('userid')} style={{'padding':'3px 16px','display':'inline-block','color':'#777','font-weight':'500','cursor':'pointer','font-size':'13px','border-radius':'4px'}}>User Id</span>
                 <input type="hidden" className="tagtype"  value={tagtype} />
                 </div>
                  <div className={`form-group pos_relative row_hb mar_t_15 mar_b_35  ${typeLogin ? "display_none" : ""}`} style={{'margin-top':'12px'}} >
                
                    {emailMsgAlert===true?<p className="errorMsgEl pos_absolute">{emailMsg}</p>:null}

                    <span ref={emailRef} onClick={()=>mAction('email')} className={activeCls==='active'?`pos_absolute ${activeCls} chngElm`:"pos_absolute chngElm"} style={{'width':'60%'}}>User ID / Email</span>
                    <input type="text" onClick={()=>fixTopact('email')} id="email"  className="form-control" autocomplete="new-email" name="email"/> 
                  </div>

                  {forgotPassword?
                  <div className={`form-group pos_relative row_hb mar_b_35 ${typeLogin ? "display_none" : ""}`}>
                    {passwordMsgAlert===true?<p className="errorMsgEl pos_absolute">{passwordMsg}</p>:null}
                    <span ref={passwordRef} onClick={()=>mAction('password')} className={actCls!='active'?"pos_absolute chngElm":`pos_absolute ${actCls} chngElm`} style={{'width':'60%'}}>Password</span>
                    <input type="password" className="form-control" name="password" id="password" onClick={()=>fixTopact('password')}  autocomplete="off"/>
                  </div>:null}
                  {typeLogin?
                  <div className={`row_hb ${typeLogin ? "" : "display_none"}`}>
                  <div className="form-group pos_relative row_hb mar_t_15 mar_b_35" style={{'margin-top':'12px'}}>
                    
                    {mobileMsgAlert===true?<p className="errorMsgEl pos_absolute">{mobileMsg}</p>:null}

                    <span ref={mobileRef} onClick={()=>mAction('mobile')} id="mobrefhead" className={activeCls==='active'?`pos_absolute ${activeCls} chngElm`:"pos_absolute chngElm"} style={{'width':'60%'}}>Mobile Number</span>
                    <input type="text"  className="form-control" id="mobile" onBlurCapture={sendOtp}   autocomplete="new-mobile" name="mobile"/> 
                    <span onClick={sendOtp} style={{'position':'absolute','top':'10px','right':'10px','font-weight': '500','color':'#1d7dc1','cursor':'pointer','font-size': '12px'}}>Send Otp</span>
                    {/* <input type="text"   placeholder="Enter Mobile Number" className="form-control" id="mobile" onChange={sendOtp} autocomplete="new-mobile" name="mobile"/>  */}


                    {/* <div className={`${timeLogin ? "" : "display_none"}`} style={{'position':'absolute','right':'10px'}}>
                      <span style={{'position':'absolute','top':'2px','right':'0','font-weight': '500','color':'#1d7dc1','cursor':'pointer','font-size': '12px'}} onClick={reSendotp} >Resend</span>
                     </div> */}
                     <p className="errorMsgEl pos_absolute" id="notsend" style={{'color':'green'}}></p>
                  </div>
                  <div className="form-group pos_relative row_hb mar_t_15 mar_b_35">
                      {otpMsgAlert===true?<p className="errorMsgEl pos_absolute">{otpMsg}</p>:null}
                   
                      <span ref={mobotpRef} id="mobotphead" onClick={()=>mAction('getotp')} className={activeCls==='active'?`pos_absolute ${activeCls} chngElm`:"pos_absolute chngElm"} style={{'width':'60%'}}>Enter OTP</span>
                     <input type="text"  className="form-control"  id="getotp" autocomplete="new-otp" name="otp"/> 
                     <div className={`${timeLogin ? "" : "display_none"}`} id="timesend" style={{'position':'absolute','top':'10px','right':'10px','color':'green'}}>
                     {minutes}:{seconds}
                     </div>
                     <p className="errorMsgEl pos_absolute" id="msgsend" style={{'color':'green'}}></p>
                  </div>
                  </div>:null}

                  <div className="form-group row_hb text_align_r">
                   
                    {forgotPassword?
                    <><a className="fnt_size_13 pull-left mar_t_10" onClick={notreg}>Not Registered yet?</a>
                    <a onClick={forgotPass} className="fnt_size_13">Forgot Password</a></>
                    :<a onClick={forgotPass} className="fnt_size_13">Login</a>}

                    <button className="btn fnt_size_13 bg_light_blue mar_l_15 txt_color_4 btn_hov pad_a_8">{forgotPassword?'Login':'Submit'} <i class="fa fa-angle-right pad_l_10" aria-hidden="true"></i></button>
                    
                  </div>
                  
                  {forgotPassword?<>
                  {notRegUser==true?<div className="row_hb">
                    <Link to="/signup/agent" title="Sign Up for Agent" className="btn pad_l_0 btn-sm mar_r_15 text_align_c mob_m_r_5 fnt_weight_500 border_rad_8 fnt_size_14 mob_min_width txt_color_3">I am an agent	<i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                    <Link to="/signup/supp" title="Sign Up for Supplier" className="btn pad_l_0 btn-sm mar_r_15 text_align_c fnt_weight_500 border_rad_8 fnt_size_14 mob_min_width txt_color_3">I am a supplier <i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                    <Link to="/signup/hotelier" title="Sign Up for Hotelier" className="btn pad_l_0 btn-sm text_align_c pull-right fnt_weight_500 border_rad_8 fnt_size_14 mob_min_width txt_color_3">I am a Hotelier <i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                  </div>:null}
                  </>:null}
                </form>
              </div>

              
            </div>

          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_r_0 pad_l_80 mob_p_l_0 bg_white min_ht_600 mob_min_ht mob_m_b_40">
            <div className="row_hb text_align_c mar_t_80 mob_m_t_b_25 mloginbx mar_b_60">
                <div className="row_hb disp_flex">
                  <div class="loginImg">
                    <img className="row_hb holiImg aniIElm mobsize" src={holiday_bg} alt=""/>
                  </div>
                </div>
                <h2 className="row_hb txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 mob_line_height_32 mon_font_size_25 mob_text_align_c fnt_size_30 line_height_42">Creating Business <br/> Opportunity for  <span className="txt_color_3 display_block">Holiday Sellers</span></h2>
            </div>
          </div>  
      </div>
    </section>
  );
}

export default Login;