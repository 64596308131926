import React,{ useState, useEffect, useRef} from 'react';
import { Waypoint } from 'react-waypoint';
import * as animationjs from '../../animation';
import holiday_bg from '../../images/holiday_bg.gif';
import agent_img from '../../images/agent_img_about.gif';
import supplier_img from '../../images/supplier_img_about.gif';
import './About.css';


function About() {

  useEffect(()=>{
    getPagetitle();
  },[]);

  const teamData = [
    {name:'Vikas Khadaria', designation:'Founder & CEO',qualification:'Law Graduate | B.com (Hons.)',about:'15+ of entrepreneurial experience Serial entrepreneur - Set up successful ventures in travel and telecom domains like Hutch, Tata Indicom and Idea and India’s top banking institutions.',socialicon:'fa-linkedin',socialLink:'https://www.linkedin.com/in/vikas-khadaria-3b243a2b'},
    {name:'Sunil Tanwar', designation:'Director (Technology)',qualification:'MCA and MSc Computer Science',about:'Oracle and Networking certified | Over 16 years of experience in IT for travel industry ',socialicon:'fa-linkedin',socialLink:'https://www.linkedin.com/in/sunil-tanwar-676b6010'},
    {name:'Rajnish Sharma', designation:'Director (Technology)',qualification:'MCA and MSc Computer Science',about:'Over 15 years of experience in travel IT domain. Instrumental in setting up complete ERP to manage planning and operations',socialicon:'fa-linkedin',socialLink:'https://www.linkedin.com/in/rajnish-sharma-43a2b814'}
  ];

  const teamData1 = [
    {name:'Raman Tuli', designation:'Country Head',sub_designation:'(Agents Onboarding)',qualification:'MBA - (Marketing)',about:'Raman is an experienced leader with a demonstrated history of working in leading travel companies with a specialization in B2B travel. He brings in key strategic experience to Travsie in growing agents & supplier network.',socialicon:'fa-linkedin',socialLink:'https://www.linkedin.com/in/raman-tuli-20548316'},
    {name:'Jay Gurnani', designation:'Business Manager',sub_designation:'(Process)',qualification:'Bsc – Bio Chemistry',about:'With more than a decade and half of experience in growth-hacking travel sales, Jay is using his analytical and leadership skills to drive Travsie’s growth and B2B expansion',socialicon:'fa-linkedin',socialLink:'https://www.linkedin.com/in/jay-gurnani-a90b6282'}
     ];

  const addAniClass = (cls,n)=>{ 
    if(window.innerWidth>767){
      animationjs.addAniClass(cls,n);
    }
  }

  const getPagetitle = ()=>{
    document.title = 'About Us';
  }

  const removeAniClass = (cls)=>{ 
    if(window.innerWidth>767){
      animationjs.removeAniClass(cls);
    }
  }

  return (
    <section className="row_hb holidaySCntr mobileCntr pad_t_50">
      <Waypoint onEnter={()=>addAniClass('foresee','1')} onLeave={()=>removeAniClass('foresee')}>
        <div className="row_hb pad_b_30 mob_p_b_35 foresee">
          <div className="container">
            <div className="row_hb text_align_c">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0">
                  <div className="row_hb pad_t_25 teamMobile">
                    <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">Redefining the  <span className="txt_color_3">Holiday Business Flow</span></h2>
                    <p className="fnt_size_20 mar_a_0 pad_t_25 mob_p_t_15 font_fam_halvetica pad_l_r_120">Travsie, India’s first B2B platform provides seamless digital experiences for small and medium size travel agents in India. Travsie connects agents directly with global suppliers, and delivers their end-to-end business needs, digitally.</p>
                    <p className="fnt_size_20 mar_a_0 pad_t_15 mob_p_t_15 font_fam_halvetica pad_l_r_120">The Travsie platform is redefining the Holiday market and its flow of business, with its full-stack, singular advanced platform that’s backed by new and unique avenues for long-term business growth for these agents. </p>
                  </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_r_0 pad_r_0 pad_t_30 mob_p_l_0 pos_relative">
                  <div className="row_hb text_align_c disp_flex mob_p_t_0 slideInRightElm slower animated">
                      <div className="pad_t_70 reDefineBox mht"></div>
                  </div>
                  <div className="row_hb text_align_c disp_flex mob_p_t_0 index_1 pos_absolute slideInLeftElm slower delay-2s animated">
                      <img className="reDefImg" src={holiday_bg} alt=""/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Waypoint>
   
      <div className="row_hb pad_b_70 mob_p_b_35">
        <div className="container">
          <div className="row_hb">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_l_0 mob_p_r_0">
                <div className="row_hb disp_flex pad_t_25">

                  <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 mob_p_l_0 mob_p_r_0">
                    <div className="row_hb">
                        <div className="row_hb mar_t_30 pad_a_25 mobMar min_ht_545 bg_what_how border_rad_8 teamMobile pos_relative">
                          <div className="aboutAnimate moveimgBgSm aboutElmA index_1 pos_absolute"></div>
                          <div className="row_hb text_align_c agentBx minht_130">
                              <img className="mobMcss mcls" src={agent_img} alt=""/>
                          </div>
                          <h2 className="ourPhElm row_hb txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50 text_align_c">Our <span className="mBlock line_height_82 txt_color_3">Philosophy</span></h2>

                          <div className="row_hb text_align_c pad_t_10 pos_relative index_2">
                            <p>We strive to identify new market opportunities, and deliver open, advanced and seamless solutions that ensure long term growth for everyone within our ecosystem. We are continuously evolving and developing new products that align to our values of fairness, transparency, and accountability.</p>
                          </div>
                        
                        </div>
                    </div>
                  </div>

                </div>
            </div>
          
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_l_0 mob_p_r_0">
                <div className="row_hb disp_flex pad_t_25">

                  <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10 mob_p_l_0 mob_p_r_0">
                    <div className="row_hb">

                        <div className="row_hb mar_t_30 pad_a_25 mobMar min_ht_545 bg_what_how border_rad_8 pos_relative teamMobile">
                          <div className="aboutAnimate moveimgBgSm aboutlast index_1 pos_absolute"></div>
                          <div className="row_hb text_align_c supBx minht_130">
                              <img className="mobMcss last"  src={supplier_img} alt=""/>
                          </div>

                          <h2 className="ourMisElm row_hb txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50 text_align_c">Our <span className="line_height_82 mBlock txt_color_3">Mission</span></h2>

                          <div className="row_hb text_align_c pad_t_10 pos_relative index_2">
                            <p>To empower holiday sellers to build profitable and sustainable businesses.</p>
                            <p>We believe that potential in every person and their business can be utilized for the growth of holiday industry. That’s why our platform is designed to democratize the holiday business where everyone harness the power of digital world.</p>
                          </div>
                        
                        </div>
                    </div>
                  </div>

                </div>
            </div>
          
          </div>
        </div>
      </div>

      <div className="row_hb pad_t_70 pad_b_70 teamMobile mob_p_b_0 mob_p_t_35 bg_col">
        <div className="row_hb theBenefits">
          <div className="container">
            <div className="row_hb">
              <div className="row_hb">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0">
                  <div className="row_hb text_align_c">
                    <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">Leadership <span className="txt_color_3">Team</span></h2>
                    <p className="row_hb pad_t_20 pad_b_20 fnt_size_20">We are a team that’s passionate about what we are doing and are redefining the way the travel industry works. With more than 10 years of experience in travel-tech domain - the Travsie team understands the real time challenges faced by the sourcing and selling side of B2B holiday industry. That’s why we work hard, innovate, and ideate to solve these problems, and offer unique digital platforms and solutions that will power successful travel business of the future.</p>
                  </div>
                </div>
              </div>

            
              {teamData.length>0?
                <div className="row_hb pad_t_50 mob_p_t_25 disp_flex mobCss">
                    {teamData.map((item,indx)=>
                    <div key={`team_${indx}`} className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mlast ">
                      <div className="row_hb teamBox border_rad_8 text_align_c pad_a_20 pos_relative">
                          <span className={`aboutComp icon_${indx}`}></span>
                          <h4 className="row_hb txt_color_5 fnt_size_18 line_height_24">{item.name} <span className="font_fam_halvetica fnt_size_15 display_block fnt_weight_300 txt_color_8">{item.designation}</span></h4>
                          <p className="row_hb fnt_size_13">{item.qualification}</p>
                          <p className="row_hb fnt_size_13 line_height_22">{item.about}</p>
                          <div className="row_hb pos_absolute sIcon index_2">
                            <a href={item.socialLink} target="_blank" className="socialElm text_align_c"><i className={`fa txt_color_4 ${item.socialicon}`} aria-hidden="true"></i></a>
                          </div>
                      </div>
                    </div>
                    )}
                </div>
              :null}

              {teamData1.length>0?
                <div className="row_hb pad_t_50 mob_p_t_25 disp_flex mobCss">
                    {teamData1.map((item,indx)=>
                    <div key={`team_${indx}`} className="col-xs-12 col-sm-3 col-md-3 col-lg-3 mlast">
                      <div className="row_hb teamBox mldx border_rad_8 text_align_c pad_a_20 pos_relative">
                          <span className={`aboutComp sub icon_${indx}`}></span>
                          <h4 className="row_hb txt_color_5 fnt_size_18 line_height_24">{item.name} <span className="font_fam_halvetica fnt_size_15 display_block fnt_weight_300 txt_color_8">{item.designation}</span>{item.sub_designation!=''?<span className="font_fam_halvetica fnt_size_15 display_block fnt_weight_300 txt_color_8">{item.sub_designation}</span>:null}</h4>
                          <p className="row_hb fnt_size_13">{item.qualification}</p>
                          <p className="row_hb fnt_size_13 line_height_22">{item.about}</p>
                          <div className="row_hb pos_absolute sIcon index_2">
                            <a href={item.socialLink} target="_blank" className="socialElm text_align_c"><i className={`fa txt_color_4 ${item.socialicon}`} aria-hidden="true"></i></a>
                          </div>
                      </div>
                    </div>
                    )}
                </div>
              :null}
            </div>
          </div>
        </div>
      </div>
     
    </section>
  );
}

export default About;