import React,{ useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog5() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Package Pricing';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">

      <div className="row_hb blogsCntr">
        <img src={`${window.location.origin}/images/blog_5.jpg`} alt="Holiday Package Pricing: A snake that eats it own tail?"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    
                    <h2 className="font_fam_montserrat">Holiday Package Pricing: A snake that eats it's own tail? <span>02 Sep 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>People have always been price conscious when it comes to their purchase habits. So, it isn’t really surprising that pricing plays a key role in determining our travel choices as well – from both the customer and the agent/supplier side. </p>
                    <p>The Indian holiday market continues to remain a price sensitive market, with service quality generally becoming secondary, as suppliers pitch their holiday products to B2B agents, OTA’s and middleman, or when they resell to B2C customers. However, this price variance also means that there is no sense of standardization in the market. </p>

                    <p>That is not all, when you throw in multiple suppliers, peak and off-seasons, offline and online prices, pre-purchased inventory, vehicle type, sightseeing – and map these to the fact that agents with better volumes get better rates, pricing in B2B holidays is a daunting and confusing maze.   </p>
                    <p>This is not at all ideal – and in many ways a counterproductive loop. Agents can’t get best prices, because they don’t have best rates from suppliers; and suppliers can’t give the best rates to agents who don’t have many customers! Insert Nietzsche’s snake eating its own tail reference here. </p>

                    <h4 className="font_fam_montserrat">“When you throw in multiple suppliers, peak and off-seasons, offline and online prices, pre-purchased inventory, vehicle type, sightseeing – pricing in B2B holidays is a daunting and confusing maze.   ” </h4>
                    <p>Now, let us look at the supplier’s side. Each supplier has his own strength when it comes to contracting for hotels and sightseeing. This fragmentation means that no singular supplier can give a good, unified quote to agents for a query – resulting in less competitive quotes to customers. Things would become easier if agents could quickly pick and choose elements from suppliers according to their strengths, but that nigh impossible in a manual process. </p>
                    <p>When we look at aspects of a holiday with online inventory, like airlines and hotels, we have seen that pricing stabilization has occurred to a degree. That is where the OTAs are winning against offline travel agents. I’ll once again admit that the standardization is difficult when you consider that a complete holiday has numerous service components attached – including choice of attractions, transfers, meals, guide etc. But, for agents to grow along with suppliers, there needs to be a way to standardize both prices and offerings for holidays – or at least bring parity and efficiency on customization. </p>
                    <p>We need to get efficient; we need to get standardised; and we possibly need to get online. Only then can we catch the proverbial pricing snake by its tail.  </p>
                
                </div>

                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog5;