//import imgPath from '../../images/banner.png';
import './Notfound.css';

function Notfound() {
  return (
    <section className="row_hb">
      <div className="container">
        <div className="row_hb notFound mid_block">
            404 Not Found
        </div>
      </div>
    </section>
  );
}

export default Notfound;