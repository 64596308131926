import React,{ useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog3() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Challenges';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">

      <div className="row_hb blogsCntr">
        <img src={`${window.location.origin}/images/blog_3.jpg`} alt="Dil Maange More: Challenges a holiday agent faces!"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    <h2 className="font_fam_montserrat">Dil Maange More: Challenges a holiday agent faces!   <span>27 Aug 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>In our previous blog we delved upon an optimistic post pandemic future – and whether the traditional travel agent was ready for it. In this blog, we decided to go deeper into the current state of the Holiday travel business, to explore the challenges Travel Agents face. </p>
                    <p>Travel Agents remain the preferred partners for travellers when it comes to holiday bookings – and have retained that edge over OTAs so far. But that dominance is not without its fair share of obstacles. We spoke to a multitude of agents to figure out what challenges they face – from both the customer and supplier side, and some of the core challenges that emerged were: </p>

                    <h3>#1 Manual customization: </h3>
                    <p>There is no easy online way to book an integrated holiday. Given that the customer is generally unsure, or even unaware, of what he/she would like to do at the holiday destination – bookings need discussion. These discussions are not only on choices, sightseeing and transport, but also majorly on pricing! That makes them time consuming and tedious. Since the agent requires multiple options at the very first stage, this tedious back and forth transfers to the supplier side as well. Add to that the inability to access and modify a holiday package real-time, you are now looking at delayed reverts to customer queries!  </p>

                    <h3>#2 The Middleman: </h3>
                    <p>The B2B market structure in India does not allow agents with lower volumes to contact large suppliers directly to get the best prices and options. Therefore, much like our farmer’s market, the ‘all-important’ middleman comes in. The entry of middlemen essentially causes three subsequent problems: decreased profit, delayed customer responses, and lowered service levels. </p>

                    <h3>#3 Lack of Specialization:</h3>
                    <p>Most travel agents in the Indian market are ‘generalists’ by default – to get clients, they will sell any and every destination – even ones they are not well versed with. As a result, the general tendency becomes to sell cheaper packages, rather than more focused, service led packages. </p>

                    <h3>#4 Lack of Access:  </h3>
                    <p>This goes back to the middleman problem. Newer agents do not have access to right suppliers and pricing, as compared to well established ones. As a result, long-term survival is an issue. Additionally, there is no price parity on offer to a new versus old agent – often driving the newer agents out of the market. </p>
                    <img style={{width:'auto'}} src={`${window.location.origin}/images/blog_3_1.jpg`} alt="Blog"/>
                    <p>Looking at these challenges, one thing becomes clear: as customers become smarter, and as good as travel agents when it comes to destination knowledge and pricing – the specialization and lack of access will emerge as primary challenges. This warrants a technological intervention and transformation – one that gives agents increased specialization, access, service, speed and stability. Only then will the holiday packages industry truly be able to reap the benefits of the revenge travel boom, post pandemic.</p>
                </div>

                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog3;