import React,{ useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import Common from '../common_component/Common';
import grawupbg from '../../images/growup_img.png';


import './Benefit.css';

function Benefit() {

  useEffect(()=>{
    getPagetitle();
  },[]);

  const arrayData = [
    {travLogo:'travLogo_1',travLogo_active:'',travData:
      [
        {icon:'hyb_icon_1',icon_active:'',title:'Hybrid Market Place',subTitle:'Sourcing becomes cheaper', className:'txt_color_3',pad_l:''},
        {icon:'hyb_icon_2_1',icon_active:'',title:'Eliminates Middleman ',subTitle:'Selling becomes faster',className:'txt_color_9',pad_l:''},
        {icon:'hyb_icon_3_1',icon_active:'',title:'Simple and Transparent ',subTitle:'Service becomes personalized',className:'txt_color_9',pad_l:''},
      ],
      mobilr_icon:'mobile_img_1',
      heading:'Hybrid Marketplace ',
      subheading:'evolved',
      description:'Unlike traditional market place, Travsie identify right suppliers and ensures buyers gain choice, value and greater efficiency. At the same time, we ensure sellers revenue grows and they gain access to a wider pool of buyers without the burden of marketing or having physical presence in the target market. Our hybrid market place creates new supply and demand, expands market participation via unique transactions, and opens new business avenues for long-term business growth.'
    },
    {travLogo:'travLogo_2',travLogo_active:'',travData:
      [
        {icon:'hyb_icon_1_1',icon_active:'',title:'Hybrid Market Place',subTitle:'Sourcing becomes cheaper',className:'txt_color_9',pad_l:''},
        {icon:'hyb_icon_2',icon_active:'',title:'Eliminates Middleman ',subTitle:'Selling becomes faster',className:'txt_color_6',pad_l:''},
        {icon:'hyb_icon_3_1',icon_active:'',title:'Simple and Transparent ',subTitle:'Service becomes personalized',className:'txt_color_9',pad_l:''},
      ],
      mobilr_icon:'mobile_img_2',
      heading:'Eliminates',
      subheading:'Middleman',
      description:'Imagine seamless transactions without any interference, exciting isn’t it! Travsie promises direct connectivity with suppliers, thereby shifting business opportunities from middlemen, straight to agents. It also enables agents to provide curated service to their customers by directly engaging with the suppliers.'
    },
    {travLogo:'travLogo_3',travLogo_active:'',travData:
      [
        {icon:'hyb_icon_1_1',icon_active:'',title:'Hybrid Market Place',subTitle:'Sourcing becomes cheaper',className:'txt_color_9',pad_l:''},
        {icon:'hyb_icon_2_1',icon_active:'',title:'Eliminates Middleman ',subTitle:'Selling becomes faster',className:'txt_color_9',pad_l:''},
        {icon:'hyb_icon_3',icon_active:'',title:'Simple and Transparent ',subTitle:'Service becomes personalized',className:'txt_color_7',pad_l:'pad_l_15'},
      ],
      mobilr_icon:'mobile_img_3',
      heading:'Simple &',
      subheading:'Transparent',
      description:'Travsie creates an open, convenient, and transparent business environment with a personalized purchasing experience. Travsie’s offerings is further enhanced by a wider choice of products, offers, and value-added services that simplify meeting customer demands. The platform also offers pricing parity, that lets new agents and businesses profitably source and supply products and services!'
    }
  ];
  
  const getPagetitle = ()=>{
    document.title = 'Benefits';
  }

  const addAniClass = (cls)=>{
    if(window.innerWidth>767){
      if (document.querySelector('.'+cls) != null) {
        if(cls==`growUpMove`){
          document.querySelector('.'+cls+' .growZoom').classList.add('zoomIn');
        }
      }
    }
  }

  const removeAniClass = (cls)=>{
    if(window.innerWidth>767){
      if (document.querySelector('.'+cls) != null) {
        if(cls==`growUpMove`){
          document.querySelector('.'+cls+' .growZoom').classList.remove('zoomIn');
        }
      }
    }
  }

  return (
    <section className="row_hb holidaySCntr pad_t_50 mob_p_t_0">
      <Common jsonData={arrayData}/>

      <Waypoint onEnter={()=>addAniClass('growUpMove')} onLeave={()=>removeAniClass('growUpMove')}>
        <div className="row_hb pad_t_70 bhheight mob_p_t_35 mob_min_ht bg_col growUpMove">
          <div className="container m_ht">
            <div className="row_hb pos_relative m_ht growZoom slower animated ">
              <div className="pos_relative index_2 pad_t_100 mob_p_t_0 col-xs-12 col-sm-5 col-md-5 col-lg-5 pad_l_0 mob_p_r_0">
                  <div className="row_hb pad_t_100 mob_p_t_0 teamMobile mob_p_b_35 mob_p_b_35_1024">
                    <h2 className="fnt_weight_800 mar_a_0 pad_t_235 mob_p_t_0 fnt_size_43 txt_color_3">Grow your <br/>business  <span className="txt_color_5"><br/>we’ve got<br/> you covered</span></h2>
                  </div>
              </div>
              <img className="growupImg mob_pos_unset mob_pos_unset_1024 pos_absolute m_ht" src={grawupbg} alt="" />
            </div>
          </div>
        </div>
      </Waypoint>
       
    </section>
  );
}

export default Benefit;