import React,{useEffect,} from 'react';
import { Helmet } from "react-helmet";  

function Thankyou() {

  useEffect(()=>{
    getPagetitle();

  },[]);
  const getPagetitle = ()=>{
    document.title = 'Thank You';
  }

  return (
    <section className="row_hb holidaySCntr mobileCntr ">
      <div className="row_hb pad_t_120 pad_b_130 teamMobile mob_p_b_0 mob_p_t_35 bg_col"> 
             
          {/* <Helmet>
                <script src="Ss.js" type="text/javascript" />
          </Helmet> */}

          <div className="container">
            <div className="row_hb">             
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pad_l_0 pad_r_0">
                  <div className="row_hb text_align_c">                   
                    <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 fnt_size_50">Thank <span className="txt_color_3">You</span></h2>
                    <p className="row_hb pad_t_20 pad_b_20 fnt_size_20">
                      Thank you for registering with Travsie. Our Team will get in touch with you shortly.
                    </p>
                  </div>
                </div>
            </div>
          </div>
      </div>
     
    </section>
  );
}

export default Thankyou;