import React,{ useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog4() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Suppliers Scared';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">

      <div className="row_hb blogsCntr">
        <img src={`${window.location.origin}/images/blog_4.jpg`} alt="Are Holiday Suppliers scared of going big?"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                   
                    <h2 className="font_fam_montserrat">Are Holiday Suppliers scared of going big? <span>31 Aug 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>In a world that has shifted from office desks to Zoom, from water cooler chats to Whatsapp groups, tedious manual processes are now outdated. But apparently the Holiday industry did not get the memo! Manual processes remain the bane for both suppliers and agents.</p>
                    <p>Ms Hetal Joshi from Rajkot told us, “If you had direct B2B rates I could pull in, and the best ones, then all I need to do is push it to my customers with my mark up to make a sale. If I had some way of integrating that as an app or on the web, my business growth will become so much easier!”</p>
                    <p>That is the agent side talking, sure, but do suppliers even want that? That’s what we wanted to find out. So, we decided to investigate what challenges suppliers face, and drumroll, here they are:</p>

                    <img style={{width:'auto'}} src={`${window.location.origin}/images/blog_4_1.jpg`} alt="Blog"/>

                    <h3>#1 Limited access to agents:  </h3>
                    <p>In a world where processes are manual and tedious – dealing with a wide variety of agents is like dealing with customers directly. Therefore, suppliers prefer to cast their net through OTAs and middlemen for smaller agents – and deal directly with bigger agents. This impacts growth both ways, denying suppliers access to nearly 85% of the holiday market.  </p>

                    <h3>#2 No Streamlined Credit/Payment Systems:  </h3>
                    <p>Since they do not deal directly with smaller agents, suppliers do not to give credit lines to them either. This makes payment collections for international travel daunting, since smaller agents cannot pay in Forex that easily. </p>

                    <h3>#3 Of course, middlemen:  </h3>
                    <p>No one really wants the middleman it seems! Middlemen often end up causing delays in closing a booking, service level issues and communication gaps. Due to additional margins, or inventory (hotel, flights) price changing due to a time-lag, you also end up with non-competitive pricing and loss of clients. That's the reason why the industry has been working on the infamous “similar hotel” option so far! </p>

                    <h3>#4 Limited online presence </h3>
                    <p>Even top suppliers have extremely limited online presence, so how will you find out what they are selling – or how will they tap an agent who is sitting in the farthest corner of India? Research data indicates that 60% suppliers either do not have a website or do not have online transaction capabilities. When it comes to promotions, they are restricted to archaic methods such as email promotions. </p>
                    <p>There is a desperate need to bring this giant, lumbering and decades old system into the online sphere. The new travel industry needs next-gen suppliers who have an online presence, can provide real time inventory through simplified processes and online transactions. The current-gen suppliers need to tap the unorganized Travel Agents market through technology and deliver the price and product parity they have been demanding for so long. </p>

                </div>

                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog4;