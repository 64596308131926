import React,{ useEffect} from 'react';
import { useHistory } from "react-router-dom";
import Readmore from './Readmore';
import './Blogs.css';

function Blog2() {
  let history = useHistory();
  useEffect(() => {
    getPagetitle();
  });

  const getPagetitle = ()=>{
    document.title = 'Revenge Travel';
  }
  return (
    <div className="row_hb">
      <section className="row_hb">

      <div className="row_hb blogsCntr">
        <img src={`${window.location.origin}/images/blog_2.jpg`} alt="Revenge Travel is coming – is the holiday industry ready?"/>
      </div>

      <div className="row_hb">
        <div className="container">
          <div className="row_hb blogBox">
              <span className="lefttagicon"></span>
              <div className="row_hb">
                <div className="blogLeft font_fam_roboto">
                    <h2 className="font_fam_montserrat">Revenge Travel is coming – is the holiday industry ready? <span>24 Aug 2021 | By <a>Vikas Khadaria</a></span></h2>
                    <p>There was a recent article in The Economic Times, that stated that the consumer has had enough of being at home. Truth be told, ‘revenge travel’ is indeed a thing now – just look at those crowded hill station photos, or 10% growth in advance bookings for some online travel agencies (OTAs)!</p>
                    <p>But does a ‘revenge travel’ boom mean that the travel industry is out of the woods? I suspect not. In my previous blog, I spoke about how the ‘travel pause’ that the pandemic brought on was exactly what the industry needed to reconfigure. That theory still holds true</p>
                    <p>Companies like Airbnb are currently reporting "strong pent-up demand" for destinations that are a ‘drive away’. As vaccinations become widespread and economies open, this demand will extend to domestic and international travel.</p>
                    <p>Our research currently indicates that the ‘new-normal’ traveller will be a slightly different creature. More demanding, clearer in his/her requirements, and less averse to risk. These factors hint that the new traveller may prefer to gravitate back to the ‘Travel Agent’ – who they can see, trust and call!</p>

                    <img src={`${window.location.origin}/images/blog_2_1.jpg`} alt="Blog"/>

                    <p>Travel agents have always held a significant position in India’s holiday market due to the customer’s confidence in the ‘human touch’. While OTAs have secured dominance in the airlines and hotel segments using technology, volume, and aggressive marketing – holidays have remained elusive. </p>
                    <p>So, if the Travel Agent is the go-to guy for the new traveller, then the question arises – is the travel agent truly ready? “I look forward to having an app of my own soon,” says Mr Amit Gupta, from Jammu “I want to give it to our customers, so that they can see what we have to offer and at what prices.”</p>

                    <h4 className="font_fam_montserrat">“I look forward to having an app of my own soon,” says Mr. Amit Gupta, from Jammu. “I want to give it to our customers, so that they can see what we have to offer ” </h4>
                    <p>For an offline system that has not evolved much over decades, the post-pandemic demand could easily become a squandered opportunity. Travel agents need online tech access that will give them wider choice of products, faster booking, competitive pricing, and high service standards – to take on the OTAs. </p>
                    <p>Just look at what new-age B2B platforms for retailers & wholesalers across other industries have launched. They have not only provided technology and access to B2B market, but have also given end-to-end business solutions. That is why we said that the travel pause was needed – it gave a perfect opportunity to overhaul an age-old, archaic institution, that is still burdened with challenges. </p>
                    <p>What are those challenges, you ask? We’ll tell you next week… </p>
                </div>

                <Readmore/>
              </div>
          </div>   
        </div>
      </div>

    </section>
    </div>
  );
}

export default Blog2;