function moveActive(cls){
  let liElm = document.querySelectorAll('.'+cls+' li');
  let moveLi;
    liElm.forEach(function(i, el) {
      moveLi = setTimeout(function() {
        if(document.querySelector('.'+cls+' li.active') != null){
          document.querySelector('.'+cls+' li.active').classList.remove('active');
        }
        liElm[el].classList.add('active');
      }, el * 1000);
  });

  if(moveLi===liElm.length+1){
    clearTimeout(moveLi);
  } 
}

// function moveActive1(cls){
//   let liElm = document.querySelectorAll('.'+cls+' li');
//   let moveLi;
//     liElm.forEach(function(i, el) {
//       moveLi = setTimeout(function() {
//         if(document.querySelector('.'+cls+' li.active') != null){
//           document.querySelector('.'+cls+' li.active').classList.remove('active');
//         }
//         liElm[el].classList.add('active');
//       }, el * 1000);
//   });

//   if(moveLi===liElm.length+1){
//     clearTimeout(moveLi);
//   } 
// }


//moveActive1('benefitsBox');



export const moveActiveMobile = (cls)=>{
  let liElm = document.querySelectorAll('.'+cls+' li');
  let moveLi;
    liElm.forEach(function(i, el) {
      moveLi = setTimeout(function() {
        if(document.querySelector('.'+cls+' li.active') != null){
          document.querySelector('.'+cls+' li.active').classList.remove('active');
        }
        liElm[el].classList.add('active');
      }, el * 1000);
  });

  if(moveLi===liElm.length+1){
    clearTimeout(moveLi);
  } 
}

export const moveActive1Mobile = (cls)=>{
  let liElm = document.querySelectorAll('.'+cls+' li');
  let moveLi;
    liElm.forEach(function(i, el) {
      moveLi = setTimeout(function() {
        if(document.querySelector('.'+cls+' li.active') != null){
          document.querySelector('.'+cls+' li.active').classList.remove('active');
        }
        liElm[el].classList.add('active');
      }, el * 1000);
  });

  if(moveLi===liElm.length+1){
    clearTimeout(moveLi);
  } 
}
 
 export const addAniClass = (cls,type)=>{
  if (document.querySelector('.'+cls) != null) {
    if(cls=='ourSolution'){
      document.querySelector('.'+cls+' .slideInRightElm').classList.add('slideInRight');
      document.querySelector('.'+cls+' .zoomInUpElm').classList.add('zoomInUp');
      document.querySelector('.'+cls+' .slideInLeftElm').classList.add('slideInLeft');
      if(type=='0'){
        setTimeout(()=>{moveActive('customizeBox')},3200);
      }
    }else if(cls=='foresee'){
      document.querySelector('.'+cls+' .slideInRightElm').classList.add('slideInRight');
      document.querySelector('.'+cls+' .slideInLeftElm').classList.add('slideInLeft');
    }else if(cls=='theBenefits'){
      document.querySelector('.'+cls+' .slideInRightElm').classList.add('slideInRight');
      if(type=='0'){
      // moveActive1('benefitsBox');
     }     
    }else if(cls=='flipCntr'){
      document.querySelector('.'+cls+' .flipElm').classList.add('flipInX');
      document.querySelector('.'+cls+' .flipElmX').classList.add('flipInX');
    }
  }
}

export const removeAniClass = (cls)=>{
  if (document.querySelector('.'+cls) != null) {
    if(cls=='ourSolution'){
      document.querySelector('.'+cls+' .slideInRightElm').classList.remove('slideInRight');
      document.querySelector('.'+cls+' .zoomInUpElm').classList.remove('zoomInUp');
      document.querySelector('.'+cls+' .slideInLeftElm ').classList.remove('slideInLeft');
    }else if(cls=='foresee'){
      document.querySelector('.'+cls+' .slideInRightElm').classList.remove('slideInRight');
      document.querySelector('.'+cls+' .slideInLeftElm ').classList.remove('slideInLeft');
    }
    else if(cls=='theBenefits'){
     // document.querySelector('.'+cls+' .slideInRightElm').classList.remove('slideInRight');
    }
    else if(cls=='flipCntr'){
      document.querySelector('.'+cls+' .flipElm').classList.add('flipInX');
      document.querySelector('.'+cls+' .flipElmX').classList.add('flipInX');
    }
  }
}