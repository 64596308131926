import React,{ useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import * as animationjs from '../../animation';
import hyb_icon_1 from '../../images/hyb_icon_1.png';
import hyb_icon_2 from '../../images/hyb_icon_2.png';
import hyb_icon_3 from '../../images/hyb_icon_3.png';
import bg_gray_logo from '../../images/bg_gray_logo.png';
import mobile_img_0 from '../../images/mobile_img_0.jpg';

import './Common.css';

function Common(prop) {

  useEffect(()=>{
    
  },[]);

  const addAniClass = (cls,n)=>{
    if(window.innerWidth>767){
      animationjs.addAniClass(cls,n);
    }
  }

  const removeAniClass = (cls)=>{
    if(window.innerWidth>767){
      animationjs.removeAniClass(cls);
    }
  }

  return (
    <section className="row_hb">
      {prop.homeData==true?
      <div className="row_hb pad_t_100 pad_b_100 m_p_t_b_50">
        <div className="container">
          <div className="row_hb">
            <div className="col-xs-12 col-sm-5 pos_relative col-md-5 col-lg-5 pad_l_0 mob_m_r_0 mob_p_r_0">
                <div className="aboutAnimate mobileRes index_1 pos_absolute"></div>
                <div className="row_hb pad_t_25 index_2 pos_relative teamMobile">
                  <h2 className="txt_color_5 fnt_weight_800 mar_a_0 mob_text_align_c pad_a_0 fnt_size_50 line_height_60">About <span className="mob_d_block line_height_82 txt_color_3">travsie</span></h2>
                  
                  <div className="row_hb mobile_Display mob_d_block display_none">
                      <img src={mobile_img_0} alt=""/>
                  </div>

                  <div className="row_hb text_align_c mob_d_block display_none pad_b_15">
                  <Link to="/benefit" className="btn btn-sm btnAgent bg_light_blue txt_color_4 text_align_c fnt_weight_500 border_rad_8 fnt_size_17 min_width_200">Know  More <i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                  </div>

                  <p className="fnt_size_22 mar_a_0 pad_t_20 pad_b_10 font_fam_halvetica pad_r_10">Travsie resolves the global challenge of holiday customization through its Holiday Search Engine and scalable tech. Our platform connects agents directly with global suppliers and delivers their end-to-end business needs, digitally.</p>
                  

                  <div className="row_hb">
                    <Link to="/about" className="btn btn-sm text_align_c pad_l_0 fnt_weight_500 mob_font_size_15 fnt_size_17  txt_color_3">Read More <i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                  </div>

                </div>
            </div>

            <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7 pad_r_0 mob_d_none">
                  <div className="row_hb mobile_Display mob_d_block_1024 display_none">
                      <img className="row_hb mob_m_t_25" src={mobile_img_0} alt=""/>
                  </div>
                <div className="row_hb hybrideBox pos_relative pad_t_50 mob_d_none_1024">

                  <div className="travHubBox">
                      <img src={bg_gray_logo} alt=""/>
                  </div>

                  <ul className="row_hb pad_l_300 pos_relative index_2">
                    <li>
                      <div className="hyb_icon">
                        <img src={hyb_icon_1} alt=""/>
                      </div>
                      <div className="hyb_text">
                          <h3 className="fnt_weight_700 fnt_size_20 pad_a_0 txt_color_3">Hybrid Market Place <span className="display_block txt_color_8 font_fam_halvetica fnt_size_16 fnt_weight_300">Sourcing becomes cheaper</span></h3>
                      </div>
                    </li>

                    <li>
                      <div className="hyb_icon">
                        <img src={hyb_icon_2} alt=""/>
                      </div>
                      <div className="hyb_text">
                          <h3 className="fnt_weight_700 fnt_size_20 pad_a_0 txt_color_6">Eliminates Middleman  <span className="display_block txt_color_8 font_fam_halvetica fnt_size_16 fnt_weight_300">Selling becomes faster</span></h3>
                      </div>
                    </li>

                    <li>
                      <div className="hyb_icon">
                        <img src={hyb_icon_3} alt=""/>
                      </div>
                      <div className="hyb_text">
                          <h3 className="fnt_weight_700 fnt_size_20 pad_a_0 txt_color_7">Simple and Transparent  <span className="display_block txt_color_8 font_fam_halvetica fnt_size_16 fnt_weight_300">Service becomes personalized</span></h3>
                      </div>
                    </li>
                  </ul>

                  <div className="row_hb text_align_c pad_t_15">
                  <Link to="/benefit" className="btn btn-sm btnAgent mar_l_105 bg_light_blue txt_color_4 text_align_c fnt_weight_500 border_rad_8 fnt_size_17 min_width_200">Know  More <i className="fa fa-angle-right pad_l_10" aria-hidden="true"></i></Link>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className="row_hb">
        {prop.jsonData.length>0?
          <div className="row_hb">
            {prop.jsonData.map((item,key)=>
              <div key={`listing_${key}`} className={`setbgEvenElm row_hb pos_relative pad_t_70 pad_b_50 m_p_t_b_25`}>
                <div className={`aboutAnimate moveimgBgSmBnft countAnim_${key} index_1 pos_absolute`}></div>
                <Waypoint onEnter={()=>addAniClass('foresee','1')} onLeave={()=>removeAniClass('foresee')}>
                  <div className={key==1?`row_hb foresee`:`row_hb`}>
                    <div className="container">
                      <div className="row_hb">
                        <div className={key==1?`col-xs-12 col-sm-6 col-md-6 slideInLeftElm mob_d_none animated slow col-lg-6 pad_l_0`:`col-xs-12 col-sm-6 col-md-6 mob_d_none col-lg-6 pad_l_0`}>
                            
                            <div className="row_hb mob_d_block_1024 display_none">
                                <img className="row_hb mob_m_t_25 mob_m_t_0_1024" src={`${window.location.origin}/images/${item.mobilr_icon}.jpg`} alt=""/>
                            </div>

                            <div className="row_hb hybrideBox innerElm pad_t_30 pos_relative mob_d_none_1024">
                              <div className="travHubBox innerElm">
                                  <img src={`${window.location.origin}/images/${item.travLogo}.png`} alt=""/>
                              </div>
                                {item.travData.length>0?
                                <ul className="row_hb pad_l_160 pos_relative index_2">

                                  {item.travData.map((data,ind)=>
                                    <li className={`${data.pad_l}`} key={`sub_listing_${ind}`}>
                                      <div className="hyb_icon">
                                        <img src={`${window.location.origin}/images/${data.icon}.png`} alt=""/>
                                      </div>
                                      <div className="hyb_text">
                                          <h3 className={`fnt_weight_700 fnt_size_20 pad_a_0 ${data.className}`}>{data.title} <span className={`display_block ${data.className} font_fam_halvetica fnt_size_16 fnt_weight_300`}>{data.subTitle}</span></h3>
                                      </div>
                                    </li>
                                  )}

                                </ul>:null}
                            </div>
                        </div>
                        
                        <div className={key==1?`col-xs-12 col-sm-6 col-md-6 slideInRightElm animated slow col-lg-6 pad_r_0 pos_relative index_2 mob_p_l_0`:`col-xs-12 col-sm-6 col-md-6 col-lg-6 pad_r_0 mob_p_l_0 pos_relative index_2`}>
                            <div className="row_hb teamMobile">
                              <h2 className="txt_color_5 fnt_weight_800 mar_a_0 pad_a_0 mob_text_align_c fnt_size_50 line_height_42"><span className="line_height_82 txt_color_3 display_block">{item.heading}</span> {item.subheading} </h2>
                              
                              <div className="row_hb mobile_Display mob_d_block display_none">
                                  <img src={`${window.location.origin}/images/${item.mobilr_icon}.jpg`} alt=""/>
                              </div>
                              
                              <p className="fnt_size_20 mar_a_0 pad_t_20 pad_b_20 font_fam_halvetica pad_r_10">{item.description}</p>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Waypoint>
              </div>
            )}
          </div>
        :null}
      </div>
    }
    </section>
  );
}

export default Common;